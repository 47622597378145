import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormGroupName } from '@angular/forms';
import { ProviderDataValidators as Validators } from '@app/modules/data-valiidator';
import { OrganisationService } from '@app/core/organisation.service';
import { DocumentsService } from '@app/Organisation-admin/documents/documents.service';
import { SettingsService } from '@app/settings/settings.service';
import { finalize } from 'rxjs/operators';
import { UserService } from '@app/core/user.service';
import { ModalService } from '@app/core/modal/modal.service';
import { take } from 'rxjs/operators';
import { NgbDateStruct, NgbDatepickerConfig } from '@ng-bootstrap/ng-bootstrap';
import { CustomDialogComponent } from '@app/shared/modal/customDialog/customDialog.component';
import { ToasterService } from '@app/shared/toaster/toastr.service';
import { TranslateService } from '@ngx-translate/core';
// import { NgbActiveModal, NgbDateStruct, NgbDatepickerConfig } from '@ng-bootstrap/ng-bootstrap';
import { MatChipInputEvent } from '@angular/material/chips';
import stringInfo from '../../../../translations/en-US.json';
import { BackendErrorService } from '@app/Organisation-admin/backenederror.service';
import { SidenavService } from '@app/core/modal/sidenav.service';

@Component({
  selector: 'app-create-new-version',
  templateUrl: './create-new-version.component.html',
  styleUrls: ['./create-new-version.component.scss']
})
export class CreateNewVersionComponent implements OnInit {
  InviteEditorForm: FormGroup;
  submitted = false;
  isLoading = false;
  success: boolean;
  OrganisationID: string | null;
  guidSubscription: any;
  oginUser: any;
  EditorList: object[];
  loginUsername: string;
  loginUserEmail: string;
  addOnBlur = true;
  dropdownList: any = [];
  selectedItems: any = [];
  dropdownSettings = {};
  error: any;
  EditorSelected = true;
  majorClicked: boolean = false;
  minorClicked: boolean = false;
  patchClicked: boolean = false;
  isShowInfo: boolean = false;
  versionMajor: number;
  versionMinor: number;
  versionPatch: number;
  noPreviousEditors: any = false;
  SelectVesrion: FormGroupName;
  hasFormBeenSubmitted: boolean = false;
  createNewVersion = stringInfo.Create_new_version.New_Version;
  loading = true;
  private nameColors = new Map<string, string>();

  @Input() DocumentGuid: string;
  @Input() title_tag: string;
  @Input() DocumentVersion: string;
  @Input() DocCreatedBy: string;
  @Input() docTitle: string;
  loginUser: any;
  minDate: NgbDateStruct;

  major: number = 0;
  minor: number = 0;
  patch: number = 0;
  username: string = '';
  UsersList: object[];
  DataList: object[];
  selected_option: any;
  oldEditorListGuid: any;
  oldeditors: any = [];
  version: any;
  isChecked: boolean = false;
  changehandler: any[];
  statusData: any;
  VersionList = [
    { id: 'major', name: 'Major' },
    { id: 'minor', name: 'Minor' },
    { id: 'patch', name: 'Patch' }
  ];
  backend_error: any;

  constructor(
    private backendErrorService: BackendErrorService,
    // public activeModal: NgbActiveModal,
    private orgService: OrganisationService,
    private formBuilder: FormBuilder,
    private documentsService: DocumentsService,
    private settingsService: SettingsService,
    private modalService: ModalService,
    private toaster: ToasterService,
    private translate: TranslateService,
    private userservice: UserService,
    private sidenavService: SidenavService
  ) {}

  ngOnInit() {
    this.selected_option = 'Version';
    this.loginUser = this.userservice.getuserdetails();
    this.loginUsername = this.loginUser.user['username'];
    this.loginUserEmail = this.loginUser.user['email'];
    this.guidSubscription = this.orgService.__organisation_guid.subscribe((guid: string) => {
      this.OrganisationID = guid;
      this.minDate = this.documentsService.getTomorrowDate();
      this.getInviteEditorForm();
      this.getEditorList();
      this.getUserList();

      var digits = this.DocumentVersion.split('.');
      var realDigits = digits.map(Number);
      this.major = realDigits[0];
      this.minor = realDigits[1];
      this.patch = realDigits[2];
    });
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'guid',
      textField: 'FullName',
      itemsShowLimit: 10,
      enableCheckAll: false,
      allowSearchFilter: true
    };
    this.loading = false;
  }
  ngOnDestroy(): void {
    this.guidSubscription.unsubscribe();
  }

  onItemSelect(item: any) {
    item.selected = !item.selected;
    if (item.selected) {
      this.selectedItems.push(item);
    } else {
      this.selectedItems = this.selectedItems.filter((x: any) => x.guid !== item.guid);
    }
    this.checkEditorSelection();
  }
  removeItem(item: any) {
    this.selectedItems = this.selectedItems.filter((x: any) => x.guid !== item.guid);
    const userIndex = this.UsersList.findIndex((x: any) => x.guid === item.guid);
    if (userIndex !== -1) {
      this.UsersList[userIndex]['selected'] = false;
    }
    this.checkEditorSelection();
  }

  onItemDeSelect(items: any) {
    this.checkEditorSelection();
  }

  checkEditorSelection() {
    this.selectedItems.length > 0 ? (this.EditorSelected = true) : (this.EditorSelected = false);
  }

  getInviteEditorForm() {
    this.InviteEditorForm = this.formBuilder.group({
      select_version: ['', [Validators.required()]],
      // major: ['',[Validators.required()]],
      // minor: ['',[Validators.required()]],
      // patch: ['',[Validators.required()]],
      due_date: ['', [Validators.required(), Validators.DateIsAfterToday()]],
      comment: ['', Validators.maxLength(4096)]
    });
  }

  get myForm() {
    return this.InviteEditorForm.get('select_version');
  }
  get f() {
    return this.InviteEditorForm.controls;
  }

  private getUserList(): void {
    this.settingsService.getOrganizationPeopleList(this.OrganisationID).subscribe(
      data => {
        this.DataList = data.data;
        this.UsersList = this.DataList.filter(x => {
          return x['roles'].includes(
            'document_editor' || (this.DocCreatedBy == this.loginUserEmail && this.loginUserEmail == x['email'])
          );
        });
        this.UsersList.forEach(user => {
          user['FullName'] = user['first_name'] + ' ' + user['last_name'] + '\n(' + user['email'] + ')';
        });
        this.UsersList.sort((a, b) => a['FullName'].localeCompare(b['FullName']));
      },
      error => {
        this.backendErrorService.setBackendError(error); // Pass the error.error value to the BackendErrorService
        this.backendErrorService.backendError$.subscribe(error => {
          this.backend_error = error;
        });
      }
    );
  }
  private getEditorList(): void {
    this.documentsService.getDocumentEditorList(this.OrganisationID, this.DocumentGuid).subscribe(
      data => {
        this.EditorList = data;
        if (this.EditorList) {
          this.EditorList.forEach(editor => {
            var initials = editor['user']['name'].match(/\b(\w)/g);
            editor['initials'] = initials.join('').toUpperCase();
          });
        }
        if (this.EditorList.length > 0) {
          this.noPreviousEditors = true;
        }
      },
      error => {
        this.backendErrorService.setBackendError(error); // Pass the error.error value to the BackendErrorService
        this.backendErrorService.backendError$.subscribe(error => {
          this.backend_error = error;
        });
      }
    );
  }

  selectOldEditor(item: any, $event: any) {
    var checked = $event.target.checked;
    this.isChecked = $event.target.checked;
    if (checked) {
      this.oldeditors.push(item);
    } else {
      var index = this.oldeditors.indexOf(item);
      this.oldeditors.splice(index, 1);
    }
  }
  radioChangeHandler($event: any) {
    this.selected_option = $event;
    if ($event == 'major') {
      this.isShowInfo = true;
      this.versionMajor = this.major + 1;
      this.versionMinor = 0;
      this.versionPatch = 0;
      console.log(this.versionMajor);
    } else if ($event == 'minor') {
      this.isShowInfo = true;

      this.versionMinor = this.minor + 1;
      this.versionMajor = this.major;
      this.versionPatch = 0;
      console.log(this.versionMinor);
    } else if ($event == 'patch') {
      this.isShowInfo = true;
      this.versionPatch = this.patch + 1;
      this.versionMajor = this.major;
      this.versionMinor = this.minor;
      console.log(this.versionPatch);
    }
    this.version = $event;
  }
  getVersionString() {
    if (this.versionMajor !== undefined && this.versionMinor !== undefined && this.versionPatch !== undefined) {
      return `${this.versionMajor}.${this.versionMinor}.${this.versionPatch}`;
    }
    return '';
  }
  save() {
    this.backend_error = '';
    let editor: any = [];
    // let version: any = [];
    this.submitted = true;
    // let editorData: any;
    this.hasFormBeenSubmitted = true;

    if (this.InviteEditorForm.valid && this.selectedItems.length > 0) {
      editor = this.selectedItems.map((item: any) => {
        return item.guid;
      });

      this.checkEditorSelection();
      this.isLoading = true;

      let editorData = this.InviteEditorForm.value;

      editorData['editors'] = editor;
      editorData['version'] = this.version;

      editorData['editors'] = [...editorData['editors'], ...this.oldeditors];
      const myDate = new Date(
        editorData['due_date'].year,
        editorData['due_date'].month - 1,
        editorData['due_date'].day + 1
      );
      editorData['due_date'] = myDate.toISOString().split('T')[0];

      this.submitVersionEditor(editorData);
    } else {
      this.checkEditorSelection();
      return;
    }
  }
  changeName(statusData: any) {
    this.documentsService.setStatus(statusData);
  }
  closeNav() {
    this.sidenavService.close(true);
  }
  submitVersionEditor(editorData: any): void {
    // delete editorData['major'];
    // delete editorData['minor'];
    // delete editorData['patch'];

    this.documentsService
      .SubmitVesrionDetails(this.OrganisationID, this.DocumentGuid, editorData)
      .pipe(
        finalize(() => {
          this.InviteEditorForm.markAsPristine();
          this.isLoading = false;
        })
      )
      .subscribe(
        (data: any) => {
          this.statusData = data;
          this.changeName(this.statusData);
          this.success = true;
          this.error = {
            due_date: ''
          };
          this.submitted = false;
          this.backend_error = '';
          this.sidenavService.updateData({ isUpdate: true, result: data });
          this.sidenavService.close(false);
          // this.translate.get('Create_new_version.new_version_toaster_message.new_version').subscribe(res => {
          //   this.toaster.showSuccess(res);
          // });
          // this.activeModal.close(true);
        },
        (error: any) => {
          this.isLoading = false;
          this.submitted = false;
          this.backendErrorService.setBackendError(error); // Pass the error.error value to the BackendErrorService
          this.backendErrorService.backendError$.subscribe(error => {
            this.backend_error = error;
            this.sidenavService.updateData(this.backend_error, true);
          });
        }
      );
  }
  getInitials(first_name: string, last_name: string): string {
    const firstNameInitial = first_name.charAt(0);
    const lastNameInitial = last_name.charAt(0);
    return `${firstNameInitial}${lastNameInitial}`;
  }
  getColor(name: string): string {
    if (this.nameColors.has(name)) {
      return this.nameColors.get(name)!;
    } else {
      const color = this.getRandomColor();
      this.nameColors.set(name, color);
      return color;
    }
  }
  getRandomColor(): string {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  list(username: string) {
    this.username = username;
  }
  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
  }
}
