import { Component, OnInit, Input, inject, ViewChildren, ElementRef, EventEmitter } from '@angular/core';
import { FormControl, FormBuilder, FormGroup } from '@angular/forms';
import { NgxMaterialTimepickerTheme } from 'ngx-material-timepicker';
import { OrganisationAdminService } from '@app/Organisation-admin/organisation-admin.service';
import { OrganisationService } from '@app/core/organisation.service';
import * as _ from 'lodash';
import { DocumentsService } from '../documents.service';
import { TranslateService } from '@ngx-translate/core';
import { ToasterService } from '@app/shared/toaster/toastr.service';
import { ProviderDataValidators as Validators } from '@app/modules/data-valiidator';
import { CustomDialogComponent } from '@app/shared/modal/customDialog/customDialog.component';
import { ModalService } from '@app/core/modal/modal.service';
import { take } from 'rxjs/operators';
import stringInfo from '../../../../translations/en-US.json';
import { BackendErrorService } from '@app/Organisation-admin/backenederror.service';
import { GoogleDriveUploadService } from '../google-drive-upload.service';
import { SidenavService } from '@app/core/modal/sidenav.service';
import { CustomsidenavComponent } from '@app/shared/modal/customsidenav/customsidenav.component';

declare var require: any;
const moment = require('moment');

export interface Category {
  value: String;
  viewValue: string;
}
export interface Type {
  value: String;
  viewValue: string;
}

@Component({
  selector: 'app-create-template',
  templateUrl: './create-template.component.html',
  styleUrls: ['./create-template.component.scss']
})
export class CreateTemplateComponent implements OnInit {
  @Input() dialogData: any = null;
  time = stringInfo.Create_Template.Time;
  version = stringInfo.Create_Template.Version_Place;
  title = stringInfo.Create_Template.Template_Title;
  isloading = false;
  FolderList: object[];
  SubFolderList: object[];
  document_types: any = [];
  OrganisationID: string;
  DocStoreguid: string;
  addTemplateForm: FormGroup;
  selectFolderForm: FormGroup;
  submitted = false;
  allowed_duration_data: {};
  allowed_duration_selected: string;
  allowed_duration: any = [];
  training_allowed_duration_data: {};
  training_allowed_duration_selected: string;
  training_allowed_duration: any = [];
  form_error: any;
  type: string;
  document_type: any = '';
  error_message: any;
  folder_name: any;
  disabledBtn = true;
  createDocStorageId: string = 'create_doc_folder_data';
  createDoctemplateStorageId: string = 'create_doc_template_data';
  darkTheme: NgxMaterialTimepickerTheme = {
    container: {
      bodyBackgroundColor: '#fff',
      buttonColor: '#FFFFFF'
    },
    dial: {
      dialBackgroundColor: '#555'
    },
    clockFace: {
      clockFaceBackgroundColor: '#fff',
      clockHandColor: 'black',
      clockFaceTimeInactiveColor: 'black'
    }
  };
  fileName: string | null = null;
  error: any;
  selected_option: any;
  selectedTemplateType: any = '';
  subfolder: any[];
  checkedItem: string;
  template_name: any = '';
  template_guid: any;
  checkFileFormat: any;
  templateTypeList: any;
  template_type: any = '';
  time_12: string = '';
  docx = 'docx';
  doc = 'doc';
  pptx = 'pptx';
  xlsx = 'xlsx';
  pdf = 'pdf';
  xls = 'xls';
  ppt = 'ppt';
  presentation = 'presentation';
  spreadsheet = 'spreadsheet';
  document = 'document';
  templateTypeForm = 'form';
  TemplateCreate_Options = [
    { id: 'upload_template', name: 'Upload a template' },
    { id: 'select_template', name: 'Choose from templates' },
    { id: 'select_template_type', name: 'Create a blank template' }
  ];
  upload_template_name: any;
  guidSubscription: any;
  backend_error: any;
  errorMessage = false;
  isLoading: boolean = false;

  DocOptionsChange(option: any) {
    this.disabledBtn = true;
    this.errorMessage = false;
    this.upload_error_message = '';
    this.selected_option = option.target.value;
    if (this.selected_option == 'select_template') {
      if (option.type == 'change' && this.backend_error) {
        this.backend_error = '';
      } else if (this.template_guid) {
        this.disabledBtn = false;
      } else {
        this.disabledBtn = true;
      }
    } else if (this.selected_option == 'select_template_type') {
      if (option.type == 'change' && this.backend_error) {
        this.backend_error = '';
      } else if (
        this.document_type == this.document ||
        this.document_type == this.spreadsheet ||
        this.document_type == this.presentation ||
        this.document_type == this.pdf
      ) {
        this.disabledBtn = false;
      } else {
        this.disabledBtn = true;
      }
    } else if (option.type == 'change' && this.backend_error) {
      this.backend_error = '';
    }
  }
  openSelectTemplateForm() {
    this.SelectTemplateOpenModal();
  }

  SelectTemplateOpenModal(): void {
    this.sidenavService
      .open(CustomsidenavComponent, {
        title: 'Select Template',
        modalType: 'Select Template',
        template_guid: this.template_guid,
        storage_id: this.createDoctemplateStorageId,
        type: this.templateTypeList[0]['value'],
        template_type: this.selectedTemplateType
      })
      .pipe(take(1)) // take() manages unsubscription for us
      .subscribe((result: any) => {});
  }

  schedules = [
    {
      value: 'SEMI-ANNUALLY',
      display_value: 'Semi Annually'
    },
    {
      value: 'ANNUALLY',
      display_value: 'Annually'
    },
    {
      value: 'EVERY TWO YEARS',
      display_value: 'Every Two Years'
    }
  ];

  numbered_week = [
    {
      display_string: 'first',
      value: '1st'
    },
    {
      display_string: 'second',
      value: '2nd'
    },
    {
      display_string: 'third',
      value: '3rd'
    },
    {
      display_string: 'fourth',
      value: '4th'
    },
    {
      display_string: 'last',
      value: 'last'
    }
  ];

  days = [
    {
      display_string: 'Monday',
      value: 'mon'
    },
    {
      display_string: 'Tuesday',
      value: 'tue'
    },
    {
      display_string: 'Wednesday',
      value: 'wed'
    },
    {
      display_string: 'Thursday',
      value: 'thu'
    },
    {
      display_string: 'Friday',
      value: 'fri'
    },
    {
      display_string: 'Saturday',
      value: 'sat'
    },
    {
      display_string: 'Sunday',
      value: 'sun'
    }
  ];

  numbered_bi = [
    {
      display_string: 'Odd',
      value: '1/2'
    },
    {
      display_string: 'Even',
      value: '2/2'
    }
  ];

  numbered_quarter = [
    {
      display_string: 'first',
      value: '1/3'
    },
    {
      display_string: 'second',
      value: '2/3'
    },
    {
      display_string: 'third',
      value: '3/3'
    }
  ];

  months_half_year = [
    {
      display_string: 'January And July',
      value: '1/6'
    },
    {
      display_string: 'February And August',
      value: '2/6'
    },
    {
      display_string: 'March And September',
      value: '3/6'
    },
    {
      display_string: 'April And October',
      value: '4/6'
    },
    {
      display_string: 'May And November',
      value: '5/6'
    },
    {
      display_string: 'June And December',
      value: '6/6'
    }
  ];

  months_year = [
    {
      display_string: 'January',
      value: '1'
    },
    {
      display_string: 'February',
      value: '2'
    },
    {
      display_string: 'March',
      value: '3'
    },
    {
      display_string: 'April',
      value: '4'
    },
    {
      display_string: 'May',
      value: '5'
    },
    {
      display_string: 'June',
      value: '6'
    },
    {
      display_string: 'July',
      value: '7'
    },
    {
      display_string: 'August',
      value: '8'
    },
    {
      display_string: 'September',
      value: '9'
    },
    {
      display_string: 'October',
      value: '10'
    },
    {
      display_string: 'November',
      value: '11'
    },
    {
      display_string: 'December',
      value: '12'
    }
  ];

  public data = {
    renewal_schedule: '',
    day_of_week: '',
    day: '',
    month: '*',
    year: '*',
    time: '',
    week: ''
  };

  constructor(
    private backendErrorService: BackendErrorService,
    private orgAdminService: OrganisationAdminService,
    private formBuilder: FormBuilder,
    private orgService: OrganisationService,
    private documentsService: DocumentsService,
    private translate: TranslateService,
    private toaster: ToasterService,
    private modalService: ModalService,
    private googleDriveService: GoogleDriveUploadService,
    private sidenavService: SidenavService
  ) {}

  static convertTimeTo24Format(time: any) {
    return moment(time, ['h:mm A']).format('HH:mm');
  }

  convertTimeTo24Format(time: any) {
    return moment(time, ['h:mm A']).format('HH:mm');
  }

  onChange(evt: any) {
    if (evt.type == 'keyup' && this.backend_error) {
      this.backend_error = '';
    }
  }
  onChangeTemplate(evt: any) {
    this.document_type = evt.target.value;
    this.disabledBtn = false;
    if (evt.type == 'change' && this.backend_error) {
      this.backend_error = '';
    }
  }

  ngOnInit() {
    setTimeout(() => {
      document.getElementById('mySidenav').classList.toggle('open');
    }, 100);
    localStorage.removeItem('submittedTemplateData');
    this.selected_option = 'select_doc_opt';
    localStorage.removeItem(this.createDoctemplateStorageId);

    this.selectFolderForm = this.formBuilder.group({
      select_folder: ['', [Validators.required()]],
      select_subfolder: ['']
    });
    this.addTemplateForm = this.formBuilder.group({
      title: ['', [Validators.required(), Validators.maxLength(255)]],
      description: ['', [Validators.required(), Validators.maxLength(4096)]],
      document_type: [''],
      template_option: ['', [Validators.required()]],
      template_type: [''],
      version: ['', [Validators.required(), Validators.restrictZeroNumber()]],
      renewal_recurring: [''],
      file: ['']
    });
    this.guidSubscription = this.orgService.getSelectedOrganisationGuid().subscribe(guid => {
      this.OrganisationID = guid;
      this.documentsService.getOrganizationFolderList(this.OrganisationID).subscribe(
        data => {
          this.FolderList = data.results;
        },
        error => {
          this.error = error.error.message;
        }
      );
    });
    this.getDocuementType();
    this.getTemplateTypeList();

    this.documentsService.getDocStoreID(this.OrganisationID).subscribe(
      data => {
        this.DocStoreguid = data['organization_document_store_guid'];
      },
      error => {
        this.error = error.error.message;
      }
    );

    if (this.dialogData) {
      this.data = _.cloneDeep(this.dialogData.event.schedule);
      this.getAllowedDuration(this.dialogData.event.schedule.renewal_schedule, this.dialogData.event.allowed_duration);
      if (_.indexOf(this.dialogData.event.schedule.day, ' ') > 0) {
        const split_day = this.data.day.split(' ');
        this.data.day = split_day[0];
        this.data.day_of_week = split_day[1];
      }
      if (this.data.renewal_schedule === 'EVERY TWO YEARS') {
        const year_data = this.data.year.split('/');
        if (+year_data[0] % 2 === 1) {
          this.data.year = '1/2';
        } else {
          this.data.year = '2/2';
        }
      }
    } else {
      this.getAllowedDuration();
      this.data = {
        renewal_schedule: 'SEMI-ANNUALLY',
        time: '09:00',
        day_of_week: 'mon',
        week: null,
        day: '1st',
        month: '1/6',
        year: '*'
      };
    }
  }
  getTemplateTypeList() {
    this.documentsService.getTemplateTypeList(this.OrganisationID).subscribe((data: any) => {
      this.templateTypeList = data;
      this.isloading = false;
      this.selectedTemplateType = this.templateTypeList[0]['value'];
    });
  }
  getDocuementType() {
    this.documentsService.getOrganizationDocumentTypesList().subscribe(data => {
      if (this.selectedTemplateType === this.templateTypeForm) {
        this.document_types = data.filter((item: any) => {
          return item.slug !== this.presentation;
        });
      } else {
        this.document_types = data;
      }
    });
  }

  ngOnDestroy(): void {
    this.guidSubscription.unsubscribe();
    localStorage.removeItem(this.createDoctemplateStorageId);
  }
  onTemplateTypeChange(event: any) {
    this.errorMessage = false;
    this.template_type = event.target.value;
    this.selectedTemplateType = event.target.value;
    let template_type = this.template_name.substr(this.template_name.lastIndexOf('.') + 1);
    if (this.selectedTemplateType == this.templateTypeForm) {
      if (
        this.document_type == this.presentation ||
        this.document_type == this.doc ||
        this.document_type == this.pptx ||
        this.document_type == this.pdf
      ) {
        this.selected_option = '';
        this.addTemplateForm.get('template_option').setValue('');
        this.errorMessage = true;
      } else if (template_type == this.doc || template_type == this.pptx || template_type == this.pdf) {
        this.selected_option = '';
        this.addTemplateForm.get('template_option').setValue('');
        this.errorMessage = true;
        this.template_name = '';
      }
    }
    this.getDocuementType();
  }
  onReset() {
    this.submitted = false;
    this.addTemplateForm.reset();
    this.folder_name = '';
    this.template_name = '';
    this.selected_option = '';
    this.backend_error = '';
    localStorage.removeItem(this.createDoctemplateStorageId);
  }

  convertTo12HourFormat(time24: string): string {
    const [hour, minute] = time24.split(':').map(Number);
    const meridian = hour >= 12 ? 'PM' : 'AM';
    const hour12 = hour % 12 === 0 ? 12 : hour % 12;
    return `${hour12.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')} ${meridian}`;
  }
  onChangeTime(event: any): void {
    const time24 = event.target.value;
    const time12 = this.convertTo12HourFormat(time24);
    this.time_12 = time12;
    this.data.time = time24;
  }
  dataRefresh(event: any) {
    const renewal_schedule: string = event;
    const time: string = this.data.time;
    switch (renewal_schedule) {
      case 'WEEKLY':
        this.data = {
          renewal_schedule: renewal_schedule,
          day_of_week: 'mon',
          day: null,
          month: '*',
          year: '*',
          time: time,
          week: null
        };
        this.allowed_duration.length = 0;
        this.allowed_duration = this.getCurrentAllowedDuration(this.allowed_duration_data, renewal_schedule);
        break;
      case 'BI-WEEKLY':
        this.data = {
          renewal_schedule: renewal_schedule,
          day_of_week: 'mon',
          day: null,
          month: '*',
          year: '*',
          time: time,
          week: '1/2'
        };
        this.allowed_duration.length = 0;
        this.allowed_duration = this.getCurrentAllowedDuration(this.allowed_duration_data, renewal_schedule);
        break;
      case 'MONTHLY':
        this.data = {
          renewal_schedule: renewal_schedule,
          day_of_week: 'mon',
          day: '1st',
          month: '*',
          year: '*',
          time: time,
          week: null
        };
        this.allowed_duration.length = 0;
        this.allowed_duration = this.getCurrentAllowedDuration(this.allowed_duration_data, renewal_schedule);
        break;
      case 'BI-MONTHLY':
        this.data = {
          renewal_schedule: renewal_schedule,
          day_of_week: 'mon',
          day: '1st',
          month: '1/2',
          year: '*',
          time: time,
          week: null
        };
        this.allowed_duration.length = 0;
        this.allowed_duration = this.getCurrentAllowedDuration(this.allowed_duration_data, renewal_schedule);
        break;
      case 'QUARTERLY':
        this.data = {
          renewal_schedule: renewal_schedule,
          day_of_week: 'mon',
          day: '1st',
          month: '1/3',
          year: '*',
          time: time,
          week: null
        };
        this.allowed_duration = this.getCurrentAllowedDuration(this.allowed_duration_data, renewal_schedule);
        break;
      case 'SEMI-ANNUALLY':
        this.data = {
          renewal_schedule: renewal_schedule,
          day_of_week: 'mon',
          day: '1st',
          month: '1/6',
          year: '*',
          time: time,
          week: null
        };
        this.allowed_duration.length = 0;
        this.allowed_duration = this.getCurrentAllowedDuration(this.allowed_duration_data, renewal_schedule);
        break;
      case 'ANNUALLY':
        this.data = {
          renewal_schedule: renewal_schedule,
          day_of_week: 'mon',
          day: '1st',
          month: '1',
          year: '*',
          time: time,
          week: null
        };
        this.allowed_duration.length = 0;
        this.allowed_duration = this.getCurrentAllowedDuration(this.allowed_duration_data, renewal_schedule);
        break;
      case 'EVERY TWO YEARS':
        this.data = {
          renewal_schedule: renewal_schedule,
          day_of_week: 'mon',
          day: '1st',
          month: '1',
          year: '1/2',
          time: time,
          week: null
        };
        this.allowed_duration.length = 0;
        this.allowed_duration = this.getCurrentAllowedDuration(this.allowed_duration_data, renewal_schedule);
        break;
      default:
        this.data = {
          renewal_schedule: renewal_schedule,
          day_of_week: null,
          day: null,
          month: '*',
          year: '*',
          time: time,
          week: null
        };
        this.allowed_duration.length = 0;
        this.allowed_duration = this.getCurrentAllowedDuration(this.allowed_duration_data, renewal_schedule);
    }
  }

  getAllowedDuration(schedule?: string, duration_value?: string) {
    this.isloading = true;
    this.orgAdminService.getEventAllowedDuration().subscribe(result => {
      this.isloading = false;
      if (result && result.allowed_durations) {
        this.allowed_duration_data = result.allowed_durations;
        this.allowed_duration = schedule
          ? this.getCurrentAllowedDuration(result.allowed_durations, schedule)
          : this.getCurrentAllowedDuration(result.allowed_durations, 'SEMI-ANNUALLY');
        this.allowed_duration_selected = duration_value ? duration_value : '1 Day';
        this.time_12 = this.convertTo12HourFormat(this.data.time);
      }
    });
  }

  getCurrentAllowedDuration(data: {}, type: string) {
    for (const key in data) {
      if (`${key}` === type) {
        return `${data[key]}`.split(',');
      }
    }
  }

  timeSet(time: any) {
    this.data.time = time;
  }

  getPreparedData() {
    const schedule_data = _.cloneDeep(this.data);
    schedule_data['allowed_duration'] = this.allowed_duration_selected;
    if (schedule_data.renewal_schedule === 'DAILY') {
      schedule_data.day_of_week = 'mon-fri';
    } else if (schedule_data.renewal_schedule === 'EVERY TWO YEARS') {
      const year_data = schedule_data.year.split('/');
      if (+year_data[0] % 2 === 1) {
        schedule_data.year = '2019/2';
      } else {
        schedule_data.year = '2020/2';
      }
    }

    if (schedule_data.day !== null && schedule_data.day_of_week) {
      schedule_data.day = schedule_data.day + ' ' + schedule_data.day_of_week;
      schedule_data.day_of_week = null;
    }

    return this.orgAdminSchedularData(Object.assign({}, schedule_data));
  }

  public orgAdminSchedularData(scheduler_data: any): object {
    for (const key in scheduler_data) {
      if (scheduler_data.hasOwnProperty(key) && scheduler_data[key] === '') {
        scheduler_data[key] = null;
      } else {
      }
    }
    return scheduler_data;
  }

  file: any;
  size: any;
  fileEvent(input: any) {
    this.disabledBtn = false;
    this.file = input.target.files[0];
    if (this.file) {
      this.fileName = input.target.files[0].name;
    } else {
      this.fileName = null;
    }
    if (input.type == 'change' && this.backend_error) {
      this.backend_error = '';
    }
    if (this.file) {
      this.disabledBtn = false;
    }

    this.document_type = this.file.name.substr(this.file.name.lastIndexOf('.') + 1);
    this.size = this.file.size;
    if (
      this.selectedTemplateType == this.templateTypeForm &&
      (this.document_type == this.doc || this.document_type == this.pptx || this.document_type == this.pdf)
    ) {
      this.upload_error_message = 'Please select .docx,.xlsx format only.';
    } else if (!this.isValidFileType(this.document_type)) {
      this.upload_error_message = 'Please select .docx,.ppt,.xlsx,.pdf format only.';
    }
  }

  ngAfterViewInit() {
    this.documentsService.getItemChecked().subscribe((item: any) => {
      this.folder_name = this.documentsService.getcheckedItem();
    });

    this.documentsService.getTemplateName().subscribe((item: any) => {
      this.template_name = this.documentsService.getTemplate();
    });

    this.documentsService.getTemplateName().subscribe((item: any) => {
      this.template_guid = this.documentsService.getTemplateGuid();
      console.log(this.template_guid);
      this.disabledBtn = false;
    });
  }
  template_error_message = 'Please select at least one template.';
  upload_error_message = '';

  checkDocumentType(template_name: any) {
    let template_type = '';
    let type = template_name.substr(template_name.lastIndexOf('.') + 1);
    if (type == this.pptx || type == this.ppt) {
      template_type = this.presentation;
    } else if (type == this.docx || type == this.doc) {
      template_type = this.document;
    } else if (type == this.xlsx || type == this.xls) {
      template_type = this.spreadsheet;
    } else if (type == this.pdf) {
      template_type = this.pdf;
    }
    return template_type;
  }

  submit() {
    this.submitted = true;
    this.isLoading = true;
    if (this.addTemplateForm.valid) {
      const document_data = Object.assign({}, this.addTemplateForm.value);
      document_data['org_document_store'] = this.DocStoreguid;
      document_data['template_type'] = this.selectedTemplateType;
      if (document_data.renewal_recurring) {
        document_data['renewal_schedule'] = this.getPreparedData();
        document_data['renewal_schedule']['time'] = this.time_12;
      } else {
        document_data['renewal_schedule'] = null;
      }
      this.error_message = '';
      if (this.selected_option == 'select_template' && !this.template_name) {
        return this.template_error_message;
      } else if (this.selected_option == 'select_template' && this.template_name) {
        this.checkDocumentType(this.template_name);
        document_data['document_type'] = this.checkDocumentType(this.template_name);
        document_data['template'] = this.template_guid;
        delete document_data.file;
        this.submitCreateDocumentData(document_data);
      } else if (this.selected_option == 'select_template_type') {
        delete document_data.file;
        this.submitCreateDocumentData(document_data);
      } else if (this.selected_option == 'upload_template') {
        this.createDocumentUploadPayload(document_data);
      }
    }
  }

  isValidFileType(checkFileFormat: any) {
    let validType = false;
    if (
      checkFileFormat == this.docx ||
      checkFileFormat == this.doc ||
      checkFileFormat == this.pptx ||
      checkFileFormat == this.xlsx ||
      checkFileFormat == this.pdf
    ) {
      validType = true;
    }
    return validType;
  }
  createDocumentUploadPayload(document_data: any): void {
    this.isloading = true;
    const data = { title: document_data.title, filename: this.file.name, size: this.size, is_template: true };
    this.checkFileFormat = data.filename.substr(this.file.name.lastIndexOf('.') + 1);
    if (this.isValidFileType(this.checkFileFormat)) {
      this.upload_error_message = '';
      this.documentsService.uploadDocument(this.OrganisationID, data).subscribe(
        async (data: any) => {
          if (data['store_type'] == 'GOOGLE_DRIVE') {
            document_data['document_type'] = data.type;
            try {
              const { fileId, fileType } = await this.googleDriveService.uploadFileAndGetInfo(
                this.file,
                data['temp_token'],
                data['folder_id'],
                document_data.title
              );
              console.log('File ID:', fileId);
              console.log('File Type:', fileType);
              document_data['file'] = fileId;
              this.submitCreateDocumentData(document_data);
              // Handle successful upload
            } catch (error) {
              this.backend_error = error;
              console.error('Error uploading file:', error);
            }
          } else {
            let attributes = {
              name: data.title,
              parent: { id: data.restricted_to }
            };
            document_data['document_type'] = data.type;
            this.documentsService.uploadDocumentToBox(data.url, this.file, data.access_token, attributes).subscribe(
              (box: any) => {
                document_data['file'] = box.entries[0].id;
                this.submitCreateDocumentData(document_data);
              },
              (error: any) => {
                this.backendErrorService.setBackendError(error); // Pass the error.error value to the BackendErrorService
                this.backendErrorService.backendError$.subscribe(error => {
                  this.backend_error = error;
                });
              }
            );
          }
        },
        (error: any) => {
          this.isloading = false;
          this.submitted = false;
          this.backendErrorService.setBackendError(error); // Pass the error.error value to the BackendErrorService
          this.backendErrorService.backendError$.subscribe(error => {
            this.backend_error = error;
          });
        }
      );
    }
  }

  submitCreateDocumentData(document_data: any): void {
    this.isloading = true;
    this.documentsService.submitOrganisationTemplate(this.OrganisationID, document_data).subscribe(
      data => {
        this.isloading = false;
        this.error = {
          title: '',
          version: '',
          folder: ''
        };
        // this.modalService
        //   .custom(CustomDialogComponent, {
        //     title: 'Template created',
        //     modalType: 'Template created',
        //     DocumentGuid: data.guid,
        //     DocumentTitle: data.title
        //   })
        //   .pipe(take(1)) // take() manages unsubscription for us
        //   .subscribe((result: any) => {
        //     if (result) {
        //     } else {
        //     }
        //   });
        this.submitted = false;
        this.addTemplateForm.reset();
        this.selectFolderForm.reset();
        this.folder_name = '';
        this.selected_option = '';
        this.template_name = '';
        this.backend_error = '';
        this.isLoading = false;
        this.sidenavService.updateData({ isUpdate: true, result: data });
        this.sidenavService.close(false);
        localStorage.removeItem(this.createDocStorageId);
        localStorage.removeItem(this.createDoctemplateStorageId);
      },
      (error: any) => {
        this.isloading = false;
        this.submitted = false;
        this.backendErrorService.setBackendError(error); // Pass the error.error value to the BackendErrorService
        this.backendErrorService.backendError$.subscribe(error => {
          this.backend_error = error;
          this.isLoading = false;
          this.sidenavService.updateData(this.backend_error, true);
        });
      }
    );
  }
  closeNav() {
    this.backend_error = '';
    this.addTemplateForm.reset();
    this.sidenavService.close(true);
  }

  // SelectFolderDialog(): void {
  //   this.modalService
  //     .custom(CustomDialogComponent, { title: 'Select a folder', modalType: 'document' })
  //     .pipe(take(1)) // take() manages unsubscription for us
  //     .subscribe((result: any) => {
  //       if (result) {
  //         this.documentsService.getOrganizationFolderList(this.OrganisationID);
  //       } else {
  //       }
  //     });
  // }
  SelectFolderDialog(): void {
    if (this.backend_error) {
      this.backend_error = '';
    }
    this.sidenavService
      .open(CustomsidenavComponent, {
        title: 'Select a folder',
        storage_id: this.createDocStorageId,
        modalType: 'document'
      })
      .pipe(take(1)) // take() manages unsubscription for us
      .subscribe((result: any) => {
        if (result) {
          this.documentsService.getOrganizationFolderList(this.OrganisationID);
        }
      });
  }
}
