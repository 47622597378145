import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';

import { AuthenticationService, I18nService } from 'app/core/index';
import { UserService } from 'app/core/user.service';
import { WizardService } from '@app/home/wizard.services';
import { OrganisationService } from '@app/core/organisation.service';
import { OrganisationAdminService } from '@app/Organisation-admin/organisation-admin.service';
import { cloneDeep } from 'lodash';
import { MixpanelService } from '@app/mixpanel/mixpanel.service';

@Component({
  selector: 'app-org-admin-shell-header',
  templateUrl: './orgAdminshellHeader.component.html',
  styleUrls: ['./orgAdminshellHeader.component.scss']
})
export class OrgAdminShellHeaderComponent implements OnInit, OnDestroy {
  organisations: any = [];
  organisationID: any;
  orgName: any;
  userType: any;
  userName: string;
  selectedProgram: string;
  guidSubscription: any;
  message = 'open';
  sidenavValue = true;
  selectedIndex: number = null;
  userOrgSub: any;

  // @Input() sidenavChildCloseMessage: string;
  @Output() sidenavMessageEvent = new EventEmitter<string>();

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private organisationAdminService: OrganisationAdminService,
    private wizard: WizardService,
    private orgService: OrganisationService,
    private i18nService: I18nService,
    private user: UserService,
    private mixpanel: MixpanelService
  ) {}

  ngOnInit() {
    this.userName = this.user.getuserdetails().user['username'];
    this.userType = this.user.getUserRole();
    this.guidSubscription = this.orgService.__organisation_guid.subscribe(org_guid => {
      this.organisationID = org_guid;
    });
    this.getOrgData();
    this.userOrgSub = this.wizard.userOrganizations.subscribe(data => {
      if (data) this.organisations = data;
    });
  }

  // ngOnChanges(changes: SimpleChanges) {
  //   if (changes.sidenavChildCloseMessage.currentValue === 'open') {
  //     this.sidenavValue = true;
  //     this.openSidenav();
  //   } else {
  //     this.sidenavValue = false;
  //     this.openSidenav();
  //   }
  // }

  getOrgData(): void {
    this.wizard.getOrganizationDetails(this.organisationID).subscribe(data => {
      this.orgName = data['name'];
      localStorage.setItem('org_password_based_login', data['org_password_based_login']);
      localStorage.setItem('domain', data['domain']);
      localStorage.setItem('org_name', this.orgName);
      localStorage.setItem('org_id', this.organisationID);
      localStorage.setItem('compliance_programs', JSON.stringify(data['compliance_programs']));
      localStorage.setItem('org_type', JSON.stringify(data['org_type']));

      const credentials = JSON.parse(localStorage.getItem('credentials'));
      const user_id = credentials.user.guid;

      this.mixpanel.identify(user_id);
      this.mixpanel.setPeople({
        $email: credentials.user.username
      });
      this.mixpanel.sendCBEvent('cb_access');
    });

    this.wizard.getUserOrganizations().subscribe(data => {
      this.wizard.userOrganizations.next(data);
    });
  }

  openSidenav(): void {
    if (this.message === 'open') {
      this.message = 'close';
    } else {
      this.message = 'open';
    }
    this.sidenavMessageEvent.emit(this.message);
  }

  ngOnDestroy(): void {
    this.guidSubscription.unsubscribe();
    this.userOrgSub.unsubscribe();
  }

  // To detect organisation change
  organisationChange(org_guid: any, index: number) {
    this.orgService.setSelectedOrganisationGuid(org_guid).subscribe(data => {
      this.setIndex(index);
      this.organisationID = data;
    });
  }

  setIndex(index: number) {
    this.selectedIndex = index;
  }

  setLanguage(language: string) {
    this.i18nService.language = language;
  }

  logout() {
    this.authenticationService.logout();
  }

  get currentLanguage(): string {
    return this.i18nService.language;
  }

  get languages(): string[] {
    return this.i18nService.supportedLanguages;
  }

  get username(): string | null {
    const user = this.user.getuserdetails().user;
    return user['username'] ? user['username'] : user['email'];
  }
}
