<app-spinner *ngIf="isloading"></app-spinner>
<div id="mySidenav" class="sidenav">
  <div class="sidenav-header">
    <span class="title" translate>Create_Template.Title</span>
    <i class="fa fa-times text-black-1 closebtn" (click)="closeNav()" ngbAutoFocus translate></i>
  </div>
  <!-- <div class="row mt-2" *ngIf="backend_error">
    <div class="col-12 col-sm-12 col-lg-12 ml-5">
      <div class="text-danger" translate>* {{ backend_error }}</div>
    </div>
  </div> -->
  <form [formGroup]="addTemplateForm" class="sidenav-body form" autocomplete="off">
    <div class="form-group">
      <label for="title" class="label">Template Title</label>
      <input
        type="text"
        id="title"
        formControlName="title"
        class="form-control cb-input-focus"
        placeholder="Enter template title"
        [maxLength]="256"
        (keyup)="onChange($event)"
        required
      />
      <div
        *ngIf="
          (addTemplateForm.controls.title.touched || addTemplateForm.controls.title.dirty) &&
          addTemplateForm.controls.title.errors
        "
        class="text-danger"
      >
        <label class="mb-0" *ngIf="addTemplateForm.controls.title.errors.required" translate
          >Create_Template.Template_Req_Title</label
        >
        <label class="mb-0" *ngIf="addTemplateForm.controls.title.errors.maxLength" translate
          >Create_Template.Title must be 255 characters or less</label
        >
      </div>
    </div>
    <div class="form-group">
      <label for="description" class="label">Template Description</label>
      <textarea
        id="description"
        formControlName="description"
        class="form-control cb-input-focus"
        placeholder="Enter description"
        [maxLength]="4097"
        required
      ></textarea>
      <div
        *ngIf="
          (addTemplateForm.controls.description.touched || addTemplateForm.controls.description.dirty) &&
          addTemplateForm.controls.description.errors
        "
        class="text-danger"
      >
        <label class="mb-0" *ngIf="addTemplateForm.controls.description.errors.required" translate
          >Create_Template.*Description is required.</label
        >
        <label class="mb-0" *ngIf="addTemplateForm.controls.description.errors.maxLength" translate
          >Create_Template.Description must be 4096 characters or less</label
        >
      </div>
    </div>
    <div class="form-group">
      <label for="document_option" class="label">Select Template Type</label>
      <select
        class="form-control cb-input-focus select-dropdown"
        id="template_type"
        formControlName="template_type"
        name="template_type"
        (change)="onTemplateTypeChange($event)"
        (keydown.enter)="$event.preventDefault()"
        [ngClass]="{
          'select-with-placeholder': template_type === ''
        }"
      >
        <option value="" disabled selected hidden>Select template type</option>
        <option
          *ngFor="let template of templateTypeList; let i = index"
          [attr.data-index]="i"
          [value]="template['value']"
        >
          {{ template.value.charAt(0).toUpperCase() + template.value.slice(1) }}
        </option>
      </select>
      <div
        class="col-12 error-mssg p-0"
        *ngIf="addTemplateForm.controls.template_option.touched && addTemplateForm.controls.template_option.errors"
      >
        <label
          for="name"
          class="text-danger mb-0"
          *ngIf="addTemplateForm.controls.template_option.errors.required"
          translate
        >
          Create_Template.Template_Req_Opt
        </label>
      </div>
      <div class="col-12 error-mssg p-0" *ngIf="errorMessage">
        <label for="name" class="text-danger" *ngIf="errorMessage" translate
          >Form Template Not support Presentation You need to select proper type(.docx,.xlsx)</label
        >
      </div>
    </div>
    <div class="form-group">
      <label for="document_option" class="label">Select Template Option</label>
      <select
        class="form-control cb-input-focus select-dropdown"
        id="template_option"
        formControlName="template_option"
        name="template_option"
        (change)="DocOptionsChange($event)"
        (keydown.enter)="$event.preventDefault()"
        [ngClass]="{
          'select-with-placeholder': selected_option === 'select_doc_opt'
        }"
      >
        <option value="" disabled selected hidden>Select template option</option>

        <option
          *ngFor="let options of TemplateCreate_Options"
          [value]="options.id"
          [selected]="selected_option == options.name"
        >
          {{ options.name }}
        </option>
      </select>
      <div
        class="col-12 error-mssg p-0"
        *ngIf="addTemplateForm.controls.template_option.touched && addTemplateForm.controls.template_option.errors"
      >
        <label
          for="name"
          class="text-danger mb-0"
          *ngIf="addTemplateForm.controls.template_option.errors.required"
          translate
        >
          Create_Template.Template_Req_Opt
        </label>
      </div>
      <div class="col-12 error-mssg p-0" *ngIf="errorMessage">
        <label for="name" class="text-danger mb-0" *ngIf="errorMessage" translate
          >Form Template Not support Presentation You need to select proper type(.docx,.xlsx)</label
        >
      </div>
    </div>
    <div class=" mb-2" *ngIf="selected_option == 'upload_template'">
      <div [ngClass]="{ active: fileName }" class="file-name d-block mb-2" [title]="fileName">{{ fileName }}</div>
      <label for="file-upload" class="cb-btn-secondary" style="padding: 6px 12px; display: inline-block;">
        {{ fileName ? 'Change Template' : 'Upload Template' }}
      </label>
      <input
        id="file-upload"
        type="file"
        formControlName="file"
        name="file"
        style="height: 40px;"
        autocomplete="file"
        type="file"
        (change)="fileEvent($event)"
        [accept]="selectedTemplateType == 'form' ? '.docx,.xlsx' : '.docx,.xlsx,.pptx,.pdf'"
      />
    </div>
    <div class="col-12 error-mssg p-0" *ngIf="selected_option == 'upload_template' && upload_error_message.length">
      <label for="folder" class="text-danger mb-0" translate>{{ upload_error_message }}</label>
    </div>
    <div class="d-flex mb-3" *ngIf="selected_option == 'select_template_type'">
      <select
        class="form-control cb-input-focus select-dropdown"
        formControlName="document_type"
        (change)="onChangeTemplate($event)"
        name="document_type"
        style="height: 40px;"
        [ngClass]="{
          'select-with-placeholder': document_type === ''
        }"
      >
        <option value="" disabled selected hidden>Select Template Type</option>
        <ng-container *ngFor="let type of document_types">
          <option value="{{ type['slug'] }}" *ngIf="type['slug'] !== 'pdf'">
            {{ type['value'] }}
          </option>
        </ng-container>
      </select>
      <div
        class="col-12 error-mssg p-0"
        *ngIf="addTemplateForm.controls.document_type.touched && addTemplateForm.controls.document_type.errors"
      >
        <label
          for="name"
          class="text-danger mb-0"
          *ngIf="addTemplateForm.controls.document_type.errors.required"
          translate
          >Create_Document.Document_Type_Req</label
        >
      </div>
    </div>
    <div class="mb-3" *ngIf="selected_option == 'select_template'">
      <div [ngClass]="{ active: template_name }" class="file-name d-block " [title]="template_name">
        {{ template_name }}
      </div>
      <button class=" mt-2 cb-btn-secondary" style="padding: 6px 12px;" (click)="openSelectTemplateForm()" translate>
        {{ template_name ? 'Change Template' : 'Select Template' }}
      </button>
      <div class="col-12 error-mssg p-0" *ngIf="submitted && !template_name">
        <label for="folder" class="text-danger mb-0" translate>* {{ template_error_message }}</label>
      </div>
    </div>
    <div class="form-group">
      <label for="version" class="label">Version</label>
      <input
        type="text"
        id="version"
        formControlName="version"
        autocomplete="off"
        name="version"
        class="form-control cb-input-focus"
        placeholder="Example 1.0.0"
        (keyup)="onChange($event)"
        (keydown.enter)="$event.preventDefault()"
        required
      />
      <div
        *ngIf="
          (addTemplateForm.controls.version.touched || addTemplateForm.controls.version.dirty) &&
          addTemplateForm.controls.version.errors
        "
        class="text-danger"
      >
        <label class="mb-0" *ngIf="addTemplateForm.controls.version.errors.required" translate
          >Create_Document.Version_req</label
        >
        <label class="mb-0" *ngIf="addTemplateForm.controls.version.errors.restrictZeroNumber_error" translate
          >Create_Document.version_not_zero</label
        >
      </div>
    </div>
    <div class="input-g roup pr-2 pb-2 pt-2 pl-0  mt-2 mb-2 mr-2 ml-0">
      <label class="form-check-label dm-text-2 checkbox-2 " translate>
        <input
          formControlName="renewal_recurring"
          id="renewal_recurring"
          name="renewal_recurring"
          class="form-check-input"
          (keydown.enter)="$event.preventDefault()"
          type="checkbox"
        />

        <span class="checkmark" style="margin-top: 4px !important;" translate></span>
        <span class="recurrence">Recurrence</span>
      </label>
    </div>
    <div class="recurrence-container" *ngIf="addTemplateForm.get('renewal_recurring')?.value">
      <div class="form-group">
        <label for="time" class="label">Recurrence Time</label>
        <input
          type="time"
          [value]="data.time"
          [(ngModel)]="data.time"
          (change)="onChangeTime($event)"
          id="time"
          class="form-control cb-input-focus"
          [ngModelOptions]="{ standalone: true }"
        />
      </div>

      <div class="form-group">
        <label for="renewal_schedule" class="label">Schedule</label>
        <select
          class="form-control cb-input-focus select-dropdown"
          [(ngModel)]="data.renewal_schedule"
          [ngModelOptions]="{ standalone: true }"
          (change)="dataRefresh($event.target.value)"
        >
          <option *ngFor="let schedule of schedules" [value]="schedule.value">
            {{ schedule.display_value }}
          </option>
        </select>
      </div>

      <div class="form-group">
        <label for="duration" class="label">Duration</label>
        <select
          class="form-control cb-input-focus select-dropdown"
          [(ngModel)]="allowed_duration_selected"
          [ngModelOptions]="{ standalone: true }"
        >
          <option
            class="select-placeholder"
            *ngFor="let duration of allowed_duration"
            [value]="duration"
            translate
            translate
            >{{ duration }}</option
          >
        </select>
      </div>

      <div class="form-group" *ngIf="data.renewal_schedule === 'WEEKLY'">
        <label for="day_of_week" class="label" translate>Create_Document.Day</label>
        <select
          class="form-control cb-input-focus select-dropdown"
          [(ngModel)]="data.day_of_week"
          [ngModelOptions]="{ standalone: true }"
          (keydown.enter)="$event.preventDefault()"
          id="day_of_week"
          ng-model="day_of_week"
        >
          <option class="select-placeholder" *ngFor="let day of days" [value]="day.value"
            >{{ day.display_string }}
          </option>
        </select>
      </div>

      <div class="form-group" *ngIf="data.renewal_schedule === 'BI-WEEKLY'">
        <label for="week" class="label" translate>Create_Document.Week</label>
        <select
          class="form-control cb-input-focus select-dropdown"
          [(ngModel)]="data.week"
          [ngModelOptions]="{ standalone: true }"
          (keydown.enter)="$event.preventDefault()"
          id="week"
          ng-model="week"
        >
          <option class="select-placeholder" *ngFor="let week of numbered_bi" [value]="week.value">{{
            week.display_string
          }}</option>
        </select>
      </div>

      <div class="form-group" *ngIf="data.renewal_schedule === 'MONTHLY'">
        <label for="month" class="label" translate>Create_Document.Week_Month</label>
        <select
          class="form-control cb-input-focus select-dropdown"
          [(ngModel)]="data.day"
          [ngModelOptions]="{ standalone: true }"
          (keydown.enter)="$event.preventDefault()"
          id="month"
          ng-model="month"
        >
          <option class="select-placeholder" *ngFor="let week of numbered_bi" [value]="week.value">{{
            week.display_string
          }}</option>
        </select>
      </div>

      <div class="form-group" *ngIf="data.renewal_schedule === 'MONTHLY'">
        <label for="day_of_the_week" class="label" translate>Create_Document.Day</label>
        <select
          class="form-control cb-input-focus select-dropdown"
          [(ngModel)]="data.day_of_week"
          [ngModelOptions]="{ standalone: true }"
          (keydown.enter)="$event.preventDefault()"
          id="day_of_the_week"
          ng-model="day_of_the_week"
        >
          <option class="select-placeholder" *ngFor="let day of days" [value]="day.value"
            >{{ day.display_string }}
          </option>
        </select>
      </div>

      <ng-container *ngIf="data.renewal_schedule === 'BI-MONTHLY'">
        <div class="form-group">
          <label for="numbered_bi" class="label" translate>Create_Document.Month</label>
          <select
            class="form-control cb-input-focus select-dropdown"
            [(ngModel)]="data.month"
            [ngModelOptions]="{ standalone: true }"
            (keydown.enter)="$event.preventDefault()"
            id="numbered_bi"
            ng-model="numbered_bi"
          >
            <option class="select-placeholder" *ngFor="let month of numbered_bi" [value]="month.value">{{
              month.display_string
            }}</option>
          </select>
        </div>

        <div class="form-group">
          <label for="numbered_week" class="label" translate>Create_Document.Week_Month</label>
          <select
            class="form-control cb-input-focus select-dropdown"
            [(ngModel)]="data.day"
            [ngModelOptions]="{ standalone: true }"
            (keydown.enter)="$event.preventDefault()"
            id="numbered_week"
            ng-model="numbered_week"
          >
            <option class="select-placeholder" *ngFor="let week of numbered_week" [value]="week.value">
              {{ week.display_string }}</option
            >
          </select>
        </div>

        <div class="form-group">
          <label for="day_of_week" class="label" translate>Create_Document.Day</label>
          <select
            class="form-control cb-input-focus select-dropdown"
            [(ngModel)]="data.day_of_week"
            [ngModelOptions]="{ standalone: true }"
            (keydown.enter)="$event.preventDefault()"
            id="day_of_week"
            ng-model="day_of_week"
          >
            <option class="select-placeholder" *ngFor="let day of days" [value]="day.value">
              {{ day.display_string }}
            </option>
          </select>
        </div>
      </ng-container>

      <ng-container *ngIf="data.renewal_schedule === 'QUARTERLY'">
        <div class="form-group">
          <label for="numbered_quarter" class="label" translate>Create_Document.Month</label>
          <select
            class="form-control cb-input-focus select-dropdown"
            [(ngModel)]="data.month"
            [ngModelOptions]="{ standalone: true }"
            (keydown.enter)="$event.preventDefault()"
            id="numbered_quarter"
            ng-model="numbered_quarter"
          >
            <option class="select-placeholder" *ngFor="let month of numbered_quarter" [value]="month.value">{{
              month.display_string
            }}</option>
          </select>
        </div>

        <div class="form-group">
          <label for="numbered_week" class="label" translate>Create_Document.Week_Month</label>
          <select
            class="form-control cb-input-focus select-dropdown"
            [(ngModel)]="data.day"
            [ngModelOptions]="{ standalone: true }"
            (keydown.enter)="$event.preventDefault()"
            id="numbered_week"
            ng-model="numbered_week"
          >
            <option class="select-placeholder" *ngFor="let week of numbered_week" [value]="week.value">
              {{ week.display_string }}</option
            >
          </select>
        </div>

        <div class="form-group">
          <label for="day_of_week" class="label" translate>Create_Document.Day</label>
          <select
            class="form-control cb-input-focus select-dropdown"
            [(ngModel)]="data.day_of_week"
            [ngModelOptions]="{ standalone: true }"
            (keydown.enter)="$event.preventDefault()"
            id="day_of_week"
            ng-model="day_of_week"
          >
            <option class="select-placeholder" *ngFor="let day of days" [value]="day.value">
              {{ day.display_string }}
            </option>
          </select>
        </div>
      </ng-container>

      <ng-container *ngIf="data.renewal_schedule === 'SEMI-ANNUALLY'">
        <div class="form-group">
          <label for="months_half_year" class="label" translate>Create_Document.Month</label>
          <select
            class="form-control cb-input-focus select-dropdown"
            [(ngModel)]="data.month"
            [ngModelOptions]="{ standalone: true }"
            (keydown.enter)="$event.preventDefault()"
            id="months_half_year"
            ng-model="months_half_year"
          >
            <option class="select-placeholder" *ngFor="let month of months_half_year" [value]="month.value">{{
              month.display_string
            }}</option>
          </select>
        </div>

        <div class="form-group">
          <label for="numbered_week" class="label" translate>Create_Document.Week_Month</label>
          <select
            class="form-control cb-input-focus select-dropdown"
            [(ngModel)]="data.day"
            [ngModelOptions]="{ standalone: true }"
            (keydown.enter)="$event.preventDefault()"
            id="numbered_week"
            ng-model="numbered_week"
          >
            <option class="select-placeholder" *ngFor="let week of numbered_week" [value]="week.value">
              {{ week.display_string }}</option
            >
          </select>
        </div>

        <div class="form-group">
          <label for="day_of_week" class="label" translate>Create_Document.Day</label>
          <select
            class="form-control cb-input-focus select-dropdown"
            [(ngModel)]="data.day_of_week"
            [ngModelOptions]="{ standalone: true }"
            (keydown.enter)="$event.preventDefault()"
            id="day_of_week"
            ng-model="day_of_week"
          >
            <option class="select-placeholder" *ngFor="let day of days" [value]="day.value">
              {{ day.display_string }}
            </option>
          </select>
        </div>
      </ng-container>

      <ng-container *ngIf="data.renewal_schedule === 'ANNUALLY'">
        <div class="form-group">
          <label for="months_year" class="label" translate>Create_Document.Month</label>
          <select
            class="form-control cb-input-focus select-dropdown"
            [(ngModel)]="data.month"
            [ngModelOptions]="{ standalone: true }"
            (keydown.enter)="$event.preventDefault()"
            id="months_year"
            ng-model="months_year"
          >
            <option class="select-placeholder" *ngFor="let month of months_year" [value]="month.value">{{
              month.display_string
            }}</option>
          </select>
        </div>

        <div class="form-group">
          <label for="numbered_week" class="label" translate>Create_Document.Week_Month</label>
          <select
            class="form-control cb-input-focus select-dropdown"
            [(ngModel)]="data.day"
            [ngModelOptions]="{ standalone: true }"
            (keydown.enter)="$event.preventDefault()"
            id="numbered_week"
            ng-model="numbered_week"
          >
            <option class="select-placeholder" *ngFor="let week of numbered_week" [value]="week.value">
              {{ week.display_string }}</option
            >
          </select>
        </div>

        <div class="form-group">
          <label for="day_of_week" class="label" translate>Create_Document.Day</label>
          <select
            class="form-control cb-input-focus select-dropdown"
            [(ngModel)]="data.day_of_week"
            [ngModelOptions]="{ standalone: true }"
            (keydown.enter)="$event.preventDefault()"
            id="day_of_week"
            ng-model="day_of_week"
          >
            <option class="select-placeholder" *ngFor="let day of days" [value]="day.value">
              {{ day.display_string }}
            </option>
          </select>
        </div>
      </ng-container>

      <ng-container *ngIf="data.renewal_schedule === 'EVERY TWO YEARS'">
        <div class="form-group">
          <label for="year" class="label" translate>Create_Document.Year</label>
          <select
            class="form-control cb-input-focus select-dropdown"
            [(ngModel)]="data.year"
            [ngModelOptions]="{ standalone: true }"
            (keydown.enter)="$event.preventDefault()"
            id="year"
            ng-model="year"
          >
            <option class="select-placeholder" *ngFor="let year of numbered_bi" [value]="year.value">{{
              year.display_string
            }}</option>
          </select>
        </div>

        <div class="form-group">
          <label for="months_year" class="label" translate>Create_Document.Month</label>
          <select
            class="form-control cb-input-focus select-dropdown"
            [(ngModel)]="data.month"
            [ngModelOptions]="{ standalone: true }"
            (keydown.enter)="$event.preventDefault()"
            id="months_year"
            ng-model="months_year"
          >
            <option class="select-placeholder" *ngFor="let month of months_year" [value]="month.value">{{
              month.display_string
            }}</option>
          </select>
        </div>

        <div class="form-group">
          <label for="numbered_week" class="label" translate>Create_Document.Week_Month</label>
          <select
            class="form-control cb-input-focus select-dropdown"
            [(ngModel)]="data.day"
            [ngModelOptions]="{ standalone: true }"
            (keydown.enter)="$event.preventDefault()"
            id="numbered_week"
            ng-model="numbered_week"
          >
            <option class="select-placeholder" *ngFor="let week of numbered_week" [value]="week.value">
              {{ week.display_string }}</option
            >
          </select>
        </div>

        <div class="form-group">
          <label for="day_of_week" class="label" translate>Create_Document.Day</label>
          <select
            class="form-control cb-input-focus select-dropdown"
            [(ngModel)]="data.day_of_week"
            [ngModelOptions]="{ standalone: true }"
            (keydown.enter)="$event.preventDefault()"
            id="day_of_week"
            ng-model="day_of_week"
          >
            <option class="select-placeholder" *ngFor="let day of days" [value]="day.value">
              {{ day.display_string }}
            </option>
          </select>
        </div>
      </ng-container>
    </div>
  </form>
  <div class="sidenav-footer d-flex">
    <button
      *ngIf="!isLoading"
      type="submit"
      class="btn-save cb-btn-primary"
      [ngClass]="{ 'btn-disabled cb-btn-disabled': addTemplateForm.invalid || disabledBtn || upload_error_message }"
      [disabled]="addTemplateForm.invalid || disabledBtn || upload_error_message"
      (click)="submit()"
    >
      Save
    </button>
    <button type="submit" class="btn-save cb-btn-disabled" disabled *ngIf="isLoading">
      <i class="fa fa-spinner fa-spin"></i>
    </button>
    <button type="button" class="px-2 cb-btn-secondary" (click)="closeNav()">Cancel</button>
  </div>
</div>
