import { values } from 'lodash';
import { Component, OnInit, Type } from '@angular/core';
import { DocumentsService } from '../documents.service';
import { OrganisationService } from '@app/core/organisation.service';
import { NgbActiveModal, NgbDateStruct, NgbDatepickerConfig } from '@ng-bootstrap/ng-bootstrap';
import stringInfo from '../../../../translations/en-US.json';

interface configOption {
  label: string;
  key: string;
}
interface ShowHideColumns {
  isShowUserEmail: boolean;
  isshowStartDate: boolean;
  isshowSignature: boolean;
}

@Component({
  selector: 'app-training-reports',
  templateUrl: './training-reports.component.html',
  styleUrls: ['./training-reports.component.scss']
})
export class TrainingReportsComponent implements OnInit {
  page: number;
  dropdownSettings = {};
  OrganisationID: string | null;
  TrainingExportList: object[];
  dropDownCampaignList: object[];
  dropDownDocumentList: object[];
  dropDownUserList: object[];
  campaignList: object[];
  documentList: object[];
  userList: object[];
  guidSubscription: any;
  error: any;
  scrollLoader = false;
  pagnationData: any;
  paginatedDataUrlTraining: any;
  modalScrollDistance = 0.1;
  modalScrollThrottle = 50;
  start_date: any = '';
  end_date: any = '';
  selectedCampaignIds: string[] = [];
  selectedUserIds: string[] = [];
  selectedDocumentIds: string[] = [];
  selectedExternalDocument: string[] = [];
  campaigns: any;
  users: any;
  documents: any;
  externalDocs: any;
  loading = true;
  loadingTable = false;
  mindate: NgbDateStruct;
  maxdate: NgbDateStruct;
  trainingStatus: any[];
  selectedStatus: any;

  reportFilterColOptions: configOption[] = [
    { label: 'User Email', key: 'isShowUserEmail' },
    { label: 'Start Date', key: 'isshowStartDate' },
    { label: 'Signature', key: 'isshowSignature' }
  ];

  dropdownSetting: any = {
    singleSelection: false,
    idField: 'key',
    textField: 'value',
    selectAllText: 'Select All',
    unSelectAllText: 'Unselect All',
    itemsShowLimit: 3,
    allowSearchFilter: true
  };

  showHideColumns: ShowHideColumns = {
    isShowUserEmail: false,
    isshowStartDate: false,
    isshowSignature: false
  };
  constructor(private documentsService: DocumentsService, private orgService: OrganisationService) {}

  ngOnInit() {
    this.guidSubscription = this.orgService.getSelectedOrganisationGuid().subscribe(guid => {
      this.OrganisationID = guid;
      this.getExportTrainingList();
      this.getDocumentFilterData();
      this.getUserFilterData();
      this.getCampaignFilterData();
      this.getTrainingStatus();
    });
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'guid',
      textField: 'FullName',
      enableCheckAll: true,
      allowSearchFilter: true
    };
  }

  getTrainingStatus(): void {
    this.documentsService.getTrainingStatus().subscribe(
      data => {
        this.loading = false;
        this.trainingStatus = data;
        let index = this.trainingStatus.findIndex((item: any) => item.key.toLowerCase() === 'overdue');
        this.trainingStatus.splice(index, 1);
      },
      error => {
        this.error = error.error.message;
        console.log('Error:', error.error.message);
      }
    );
  }

  private getExportTrainingList(): void {
    this.page = 1;
    if (this.OrganisationID != null) {
      this.documentsService.getOrganizationExportTrainingList(this.OrganisationID, this.page).subscribe(
        data => {
          this.loading = false;
          this.paginatedDataUrlTraining = data;
          this.TrainingExportList = data.results;
        },
        error => {
          this.error = error.error.message;
          console.log('Error:', error.error.message);
        }
      );
    }
  }

  private getCampaignFilterData(): void {
    if (this.OrganisationID != null) {
      this.documentsService.getCampaignFilterDataList(this.OrganisationID).subscribe(
        data => {
          this.campaignList = data;
          this.dropDownCampaignList = this.campaignList.map(data => data['title']);
        },
        error => {
          this.error = error.error.message;
          console.log('Error:', error.error.message);
        }
      );
    }
  }
  private getDocumentFilterData(): void {
    if (this.OrganisationID != null) {
      this.documentsService.getDocumentFilterDataList(this.OrganisationID).subscribe(
        data => {
          this.documentList = data;
          this.dropDownDocumentList = this.documentList.map(data =>
            data['document'] ? data['document']['title'] : data['external_link']['title']
          );
        },
        error => {
          this.error = error.error.message;
          console.log('Error:', error.error.message);
        }
      );
    }
  }
  private getUserFilterData(): void {
    if (this.OrganisationID != null) {
      this.documentsService.getUserFilterDataList(this.OrganisationID).subscribe(
        data => {
          this.userList = data;
          this.userList.forEach(user => {
            user['FullName'] = user['username'] + '(' + user['email'] + ')';
          });
          this.dropDownUserList = this.userList.map(user => user['FullName']);
        },
        error => {
          this.error = error.error.message;
          console.log('Error:', error.error.message);
        }
      );
    }
  }

  onScroll() {
    if (this.paginatedDataUrlTraining?.next) {
      this.onScrollData();
    } else {
      this.scrollLoader = false;
    }
  }
  onScrollData() {
    this.scrollLoader = true;
    let traingStatus;
    if (this.selectedStatus) {
      traingStatus = this.convertArrayToQueryParams(this.selectedStatus);
    }
    this.documentsService
      .getOrganizationExportTrainingListPagination(
        this.OrganisationID,
        (this.page += 1),
        this.start_date,
        this.end_date,
        this.campaigns,
        this.users,
        this.documents,
        this.externalDocs,
        traingStatus
      )
      .subscribe(
        data => {
          this.scrollLoader = false;
          this.pagnationData = data.results;
          this.paginatedDataUrlTraining = data;
          this.TrainingExportList = this.TrainingExportList.concat(data.results);
        },
        error => {
          this.scrollLoader = false;
          this.error = error.error.message;
        }
      );
  }
  onStartDateSelection(evt: NgbDateStruct) {
    let myDate = new Date(evt.year, evt.month - 1, evt.day + 1);
    this.start_date = myDate.toISOString().split('T')[0];
    this.mindate = {
      year: myDate.getFullYear(),
      month: myDate.getMonth() + 1,
      day: myDate.getDate()
    };
  }

  onEndDateSelection(evt: NgbDateStruct) {
    let myDate = new Date(evt.year, evt.month - 1, evt.day + 1);
    this.end_date = myDate.toISOString().split('T')[0];
    this.maxdate = {
      year: myDate.getFullYear(),
      month: myDate.getMonth() + 1,
      day: myDate.getDate() - 1
    };
  }
  onEndDateDeSelection(evt: any) {
    const inputValue = evt.target.value;
    if (inputValue.trim() === '') {
      this.end_date = '';
      this.maxdate = null;
    }
  }
  onStartDateDeSelection(evt: any) {
    console.log(evt.target.value);
    const inputValue = evt.target.value;
    if (inputValue.trim() === '') {
      this.start_date = '';
      this.mindate = null;
    }
  }
  onCampaignSelect(evt: any) {
    const selectedData = this.campaignList.filter((data: any) => data['title'] === evt);
    const selectedCampaignIds = [...new Set(selectedData.map((data: any) => data['guid']))] as string[];
    this.selectedCampaignIds.push(...selectedCampaignIds);
  }
  onCampaignDeselect(evt: any) {
    const deselectedData = this.campaignList.filter((data: any) => data['title'] === evt);
    const deselectedCampaignIds = deselectedData.map((data: any) => data['guid']);
    this.selectedCampaignIds = this.selectedCampaignIds.filter(
      campaignId => !deselectedCampaignIds.includes(campaignId)
    );
  }
  onUserSelect(evt: any) {
    const selectedData = this.userList.filter((data: any) => data['FullName'] === evt);
    const selectedUserIds = [...new Set(selectedData.map((data: any) => data['guid']))] as string[];
    this.selectedUserIds.push(...selectedUserIds);
  }
  onUserDeselect(evt: any) {
    const deselectedData = this.userList.filter((data: any) => data['FullName'] === evt);
    const deselectedUserIds = deselectedData.map((data: any) => data['guid']);
    this.selectedUserIds = this.selectedUserIds.filter(userId => !deselectedUserIds.includes(userId));
  }
  onDocumentSelect(evt: any) {
    const selectedData = this.documentList.filter(
      (data: any) => (data['document'] ? data['document']['title'] : data['external_link']['title']) === evt
    );

    const selectedDocumentIds = [
      ...new Set(
        selectedData.map((data: any) => (data['document'] ? data['document']['guid'] : data['external_link']['title']))
      )
    ] as string[];

    selectedDocumentIds.forEach(id => {
      if (selectedData.some(data => data['document'] && data['document']['guid'])) {
        this.selectedDocumentIds.push(id);
      } else {
        this.selectedExternalDocument.push(id);
      }
    });
  }

  onDocumentDeselect(evt: any) {
    const deselectedData = this.documentList.filter(
      (data: any) => (data['document'] ? data['document']['title'] : data['external_link']['title']) === evt
    );
    const deselectedDocumentIds = deselectedData.map((data: any) =>
      data['document'] ? data['document']['guid'] : data['external_link']['title']
    );
    this.selectedDocumentIds = this.selectedDocumentIds.filter(
      documentId => !deselectedDocumentIds.includes(documentId)
    );
    this.selectedExternalDocument = this.selectedExternalDocument.filter(
      documentId => !deselectedDocumentIds.includes(documentId)
    );
  }
  UpdatedreportdataWithPagination() {
    this.page = 1;
    this.loadingTable = true;
    this.campaigns = this.selectedCampaignIds.map((campaignId: any = []) => `campaign=${campaignId}`).join('&');
    this.users = this.selectedUserIds.map((userId: any = []) => `user=${userId}`).join('&');
    this.documents = this.selectedDocumentIds.map((documentId: any = []) => `document=${documentId}`).join('&');
    this.externalDocs = this.selectedExternalDocument
      .map((externalDocs: any = []) => `external_docs=${externalDocs}`)
      .join('&');
    let traingStatus;
    if (this.selectedStatus) {
      traingStatus = this.convertArrayToQueryParams(this.selectedStatus);
    }
    this.documentsService
      .getOrganizationExportTrainingListPagination(
        this.OrganisationID,
        this.page,
        this.start_date,
        this.end_date,
        this.campaigns,
        this.users,
        this.documents,
        this.externalDocs,
        traingStatus
      )
      .subscribe(data => {
        this.loadingTable = false;
        this.TrainingExportList = data.results;
      });
  }

  convertArrayToQueryParams(array: { key: string; value: string }[]): string {
    return array.map(item => `status=${item.key}`).join('&');
  }
  Updatedreportdata() {
    this.campaigns = this.selectedCampaignIds.map((campaignId: any = []) => `campaign=${campaignId}`).join('&');
    this.users = this.selectedUserIds.map((userId: any = []) => `user=${userId}`).join('&');
    this.documents = this.selectedDocumentIds.map((documentId: any = []) => `document=${documentId}`).join('&');
    this.externalDocs = this.selectedExternalDocument
      .map((externalDocs: any = []) => `external_docs=${externalDocs}`)
      .join('&');
    let traingStatus;
    if (this.selectedStatus) {
      traingStatus = this.convertArrayToQueryParams(this.selectedStatus);
    }
    this.documentsService
      .getOrganizationExportTrainingListFilterwise(
        this.OrganisationID,
        this.start_date,
        this.end_date,
        this.campaigns,
        this.users,
        this.documents,
        this.externalDocs,
        traingStatus
      )
      .subscribe(data => {
        this.TrainingExportList = data;
        this.exportToCSV();
      });
  }

  exportToCSV() {
    const allCSVData = [];
    const header = ['Document Title', 'User Name', 'User Email', 'Start Date', 'End Date', 'Status'];
    allCSVData.push(header.join(','));
    this.TrainingExportList.forEach(item => {
      const flattenedItem = {
        'Document Title': item['training_campaign_material']['document']
          ? item['training_campaign_material']['document']['title']
          : item['training_campaign_material']['external_link']['title'],
        'User Name': item['user']['username'],
        'User Email': item['user']['email'],
        'Start Date': item['training_campaign']['start_date'],
        'End Date': item['training_campaign']['end_date'],
        Status: this.getStatus(item['training_campaign_material']['status'])
      };

      const values = header.map(key => flattenedItem[key]);
      allCSVData.push(values.join(','));
    });

    if (allCSVData.length > 1) {
      // Check if there's data to export
      const currentDate = new Date();
      const formattedDate = `${currentDate.getFullYear()}-${currentDate.getMonth() + 1}-${currentDate.getDate()}`;
      const fileName = `training_data_${formattedDate}.csv`;
      const combinedCSVData = allCSVData.join('\n');
      this.downloadCSV(combinedCSVData, fileName);
    }
  }

  private downloadCSV(csvData: string, fileName: string) {
    const blob = new Blob([csvData], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url);
  }

  getStatus(status: string) {
    const foundStatus = this.trainingStatus?.find((item: any) => item.key == status);
    return foundStatus?.value;
  }
}
