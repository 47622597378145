<div class="dashboard-view">
  <app-spinner *ngIf="loading"></app-spinner>
  <div class="bg-white">
    <div class="py-3 px-4">
      <div class="dashboard-title">
        Frameworks
      </div>
    </div>
    <hr class="m-0" />
    <div class="d-flex justify-content-between py-3 px-4">
      <div class="search-people position-relative" style="width: 200px;">
        <div class="input-group">
          <div class="input-group">
            <input
              id="search"
              placeholder="Search"
              [(ngModel)]="searchDocumentTitle"
              (ngModelChange)="searchFrameworks()"
              class="form-control cb-input-focus search-field cb-input"
              name="search"
              type="text"
              #titleInput
            />
          </div>
          <i class="fa fa-search search-icon"></i>
        </div>
      </div>
      <div class="d-flex">
        <button class="btn cb-btn-secondary demo-btn">Onboarding Demo <mat-icon>arrow_right</mat-icon></button>
        <button class=" ml-2 cb-icon-btn-primary action-btn" [matMenuTriggerFor]="menu">
          <mat-icon style="font-size: 16px;height: 19px;" class="mt-1">more_vert</mat-icon>
        </button>
        <mat-menu xPosition="before" #menu="matMenu" class="fixed-menu-panel">
          <button mat-menu-item (click)="openRestartDialog()">
            Restart Onboarding
          </button>
        </mat-menu>
      </div>
    </div>
    <hr class="m-0" />
    <div class="py-3 px-4">
      <div class="chart-row">
        <div
          class="d-inline-block control-chart-container position-relative"
          [class.control-chart-disabled]="frameworks[0].disabled || !frameworks[0].alreadyAddedProgram"
          *ngIf="!frameworks[0].hidden"
        >
          <ng-container *ngTemplateOutlet="frameworks[0].disabled ? comingSoon : null"> </ng-container>
          <div class="mt-3 mx-3 control-title"><img src="./../../../assets/images/core/hippa.svg" /> HIPAA</div>
          <div class="d-flex px-3 pb-3 justify-content-between">
            <div class="control-chart mt-4 ml-2 position-relative">
              <canvas id="hipaa"></canvas>
              <div class="w-100p text-center position-absolute control-summary">
                <div class="control-chart-subtitle">
                  PROGRESS
                </div>
                <div class="control-chart-total-percentage" style="font-size: 16px;">
                  {{
                    percentage(
                      summaryData['HIPAA']?.pgm_unit_status?.COMPLETED,
                      summaryData['HIPAA']?.pgm_unit_status?.INCOMPLETE +
                        summaryData['HIPAA']?.pgm_unit_status?.PENDING +
                        summaryData['HIPAA']?.pgm_unit_status?.COMPLETED
                    )
                  }}%
                </div>
              </div>
            </div>
            <!-- HIPAA Progress Section -->
            <div class="mt-4 ml-1 summary-block">
              <div class="d-flex justify-content-between">
                <span>Policies</span>
                <span
                  >{{ summaryData['HIPAA']?.policy_status?.published || 0 }}/{{
                    summaryData['HIPAA']?.policy_status?.total || 0
                  }}</span
                >
              </div>
              <div class="progress-policies progress my-2">
                <div
                  class="progress-bar"
                  [ngClass]="{
                    'bg-success':
                      percentage(
                        summaryData['HIPAA']?.policy_status?.published || 0,
                        summaryData['HIPAA']?.policy_status?.total || 0
                      ) >= 50,
                    'bg-warning':
                      percentage(
                        summaryData['HIPAA']?.policy_status?.published || 0,
                        summaryData['HIPAA']?.policy_status?.total || 0
                      ) < 50
                  }"
                  [style.width.%]="
                    percentage(
                      summaryData['HIPAA']?.policy_status?.published || 0,
                      summaryData['HIPAA']?.policy_status?.total || 0
                    )
                  "
                ></div>
              </div>
              <div class="d-flex justify-content-between">
                <span>Procedures</span>
                <span
                  >{{ summaryData['HIPAA']?.procedure_status?.published || 0 }}/{{
                    summaryData['HIPAA']?.procedure_status?.total || 0
                  }}</span
                >
              </div>
              <div class="progress-policies progress my-2">
                <div
                  class="progress-bar"
                  [ngClass]="{
                    'bg-success':
                      percentage(
                        summaryData['HIPAA']?.procedure_status?.published || 0,
                        summaryData['HIPAA']?.procedure_status?.total || 0
                      ) >= 50,
                    'bg-warning':
                      percentage(
                        summaryData['HIPAA']?.procedure_status?.published || 0,
                        summaryData['HIPAA']?.procedure_status?.total || 0
                      ) < 50
                  }"
                  [style.width.%]="
                    percentage(
                      summaryData['HIPAA']?.procedure_status?.published || 0,
                      summaryData['HIPAA']?.procedure_status?.total || 0
                    )
                  "
                ></div>
              </div>
              <div class="d-flex justify-content-between">
                <span>Evidences</span>
                <span
                  >{{ summaryData['HIPAA']?.evidence_status?.good_standing || 0 }}/{{
                    summaryData['HIPAA']?.evidence_status?.total || 0
                  }}</span
                >
              </div>
              <div class="progress-policies progress my-2">
                <div
                  class="progress-bar"
                  [ngClass]="{
                    'bg-success':
                      percentage(
                        summaryData['HIPAA']?.evidence_status?.good_standing || 0,
                        summaryData['HIPAA']?.evidence_status?.total || 0
                      ) >= 50,
                    'bg-warning':
                      percentage(
                        summaryData['HIPAA']?.evidence_status?.good_standing || 0,
                        summaryData['HIPAA']?.evidence_status?.total || 0
                      ) < 50
                  }"
                  [style.width.%]="
                    percentage(
                      summaryData['HIPAA']?.evidence_status?.good_standing || 0,
                      summaryData['HIPAA']?.evidence_status?.total || 0
                    )
                  "
                ></div>
              </div>
            </div>
          </div>
          <hr class="m-0 mx-3" *ngIf="!frameworks[0].alreadyAddedProgram" />
          <div class="d-flex justify-content-end py-3 pr-2">
            <button
              *ngIf="!frameworks[0].alreadyAddedProgram"
              class="btn-folder "
              [ngClass]="{ 'cb-btn-secondary': frameworks[0].disabled, 'cb-btn-primary': !frameworks[0].disabled }"
              (click)="frameworks[0].disabled ? handleEvent(frameworks[0]) : gotoSelectFramework(frameworks[0].name)"
            >
              {{ frameworks[0].disabled ? (complianceStatus[frameworks[0].name] ? 'Joined' : 'Join waitlist') : 'Add' }}
            </button>
          </div>
        </div>

        <!-- SOC2 Card -->
        <div
          class="d-inline-block control-chart-container position-relative"
          [class.control-chart-disabled]="frameworks[1].disabled || !frameworks[1].alreadyAddedProgram"
          *ngIf="!frameworks[1].hidden"
        >
          <ng-container *ngTemplateOutlet="frameworks[1].disabled ? comingSoon : null"> </ng-container>
          <div class="mt-3 mx-3 control-title"><img src="./../../../assets/images/core/soc2.svg" /> SOC2</div>
          <div class="d-flex px-3 pb-3 justify-content-between">
            <div class="control-chart mt-4 ml-2 position-relative">
              <canvas id="soc2"></canvas>
              <div class="w-100p text-center position-absolute control-summary">
                <div class="control-chart-subtitle">
                  PROGRESS
                </div>
                <div class="control-chart-total-percentage" style="font-size: 16px;">
                  {{
                    percentage(
                      summaryData['SOC2']?.pgm_unit_status?.COMPLETED,
                      summaryData['SOC2']?.pgm_unit_status?.INCOMPLETE +
                        summaryData['SOC2']?.pgm_unit_status?.PENDING +
                        summaryData['SOC2']?.pgm_unit_status?.COMPLETED
                    )
                  }}%
                </div>
              </div>
            </div>
            <div class="mt-4 ml-1 summary-block">
              <div class="d-flex justify-content-between">
                <span>Policies</span>
                <span
                  >{{ summaryData['SOC2']?.policy_status?.published || 0 }}/{{
                    summaryData['SOC2']?.policy_status?.total || 0
                  }}</span
                >
              </div>
              <div class="progress-policies progress my-2">
                <div
                  class="progress-bar"
                  [ngClass]="{
                    'bg-success':
                      percentage(
                        summaryData['SOC2']?.policy_status?.published || 0,
                        summaryData['SOC2']?.policy_status?.total || 0
                      ) >= 50,
                    'bg-warning':
                      percentage(
                        summaryData['SOC2']?.policy_status?.published || 0,
                        summaryData['SOC2']?.policy_status?.total || 0
                      ) < 50
                  }"
                  [style.width.%]="
                    percentage(
                      summaryData['SOC2']?.policy_status?.published || 0,
                      summaryData['SOC2']?.policy_status?.total || 0
                    )
                  "
                ></div>
              </div>
              <div class="d-flex justify-content-between">
                <span>Procedures</span>
                <span
                  >{{ summaryData['SOC2']?.procedure_status?.published || 0 }}/{{
                    summaryData['SOC2']?.procedure_status?.total || 0
                  }}</span
                >
              </div>
              <div class="progress-policies progress my-2">
                <div
                  class="progress-bar"
                  [ngClass]="{
                    'bg-success':
                      percentage(
                        summaryData['SOC2']?.procedure_status?.published || 0,
                        summaryData['SOC2']?.procedure_status?.total || 0
                      ) >= 50,
                    'bg-warning':
                      percentage(
                        summaryData['SOC2']?.procedure_status?.published || 0,
                        summaryData['SOC2']?.procedure_status?.total || 0
                      ) < 50
                  }"
                  [style.width.%]="
                    percentage(
                      summaryData['SOC2']?.procedure_status?.published || 0,
                      summaryData['SOC2']?.procedure_status?.total || 0
                    )
                  "
                ></div>
              </div>
              <div class="d-flex justify-content-between">
                <span>Evidences</span>
                <span
                  >{{ summaryData['SOC2']?.evidence_status?.good_standing || 0 }}/{{
                    summaryData['SOC2']?.evidence_status?.total || 0
                  }}</span
                >
              </div>
              <div class="progress-policies progress my-2">
                <div
                  class="progress-bar"
                  [ngClass]="{
                    'bg-success':
                      percentage(
                        summaryData['SOC2']?.evidence_status?.good_standing || 0,
                        summaryData['SOC2']?.evidence_status?.total || 0
                      ) >= 50,
                    'bg-warning':
                      percentage(
                        summaryData['SOC2']?.evidence_status?.good_standing || 0,
                        summaryData['SOC2']?.evidence_status?.total || 0
                      ) < 50
                  }"
                  [style.width.%]="
                    percentage(
                      summaryData['SOC2']?.evidence_status?.good_standing || 0,
                      summaryData['SOC2']?.evidence_status?.total || 0
                    )
                  "
                ></div>
              </div>
            </div>
          </div>

          <hr class="m-0 mx-3" *ngIf="!frameworks[1].alreadyAddedProgram" />
          <div class="d-flex justify-content-end py-3 pr-2">
            <button
              *ngIf="!frameworks[1].alreadyAddedProgram"
              class="btn-folder "
              [ngClass]="{ 'cb-btn-secondary': frameworks[1].disabled, 'cb-btn-primary': !frameworks[1].disabled }"
              (click)="frameworks[1].disabled ? handleEvent(frameworks[1]) : gotoSelectFramework(frameworks[1].name)"
            >
              {{ frameworks[1].disabled ? (complianceStatus[frameworks[1].name] ? 'Joined' : 'Join waitlist') : 'Add' }}
            </button>
          </div>
        </div>

        <!-- ISO Card -->
        <div
          class="d-inline-block control-chart-container position-relative"
          [class.control-chart-disabled]="frameworks[2].disabled || !frameworks[2].alreadyAddedProgram"
          *ngIf="!frameworks[2].hidden"
        >
          <ng-container *ngTemplateOutlet="frameworks[2].disabled ? comingSoon : null"> </ng-container>
          <div class="mt-3 mx-3 control-title"><img src="./../../../assets/images/core/iso.svg" /> ISO 27001</div>
          <div class="d-flex px-3 pb-3 justify-content-between">
            <div class="control-chart mt-4 ml-2 position-relative">
              <canvas id="iso"></canvas>
              <div class="w-100p text-center position-absolute control-summary">
                <div class="control-chart-subtitle">
                  PROGRESS
                </div>
                <div class="control-chart-total-percentage" style="font-size: 16px;">
                  {{
                    percentage(
                      summaryData['ISO']?.pgm_unit_status?.COMPLETED,
                      summaryData['ISO']?.pgm_unit_status?.INCOMPLETE +
                        summaryData['ISO']?.pgm_unit_status?.PENDING +
                        summaryData['ISO']?.pgm_unit_status?.COMPLETED
                    )
                  }}%
                </div>
              </div>
            </div>
            <div class="mt-4 ml-1 summary-block">
              <div class="d-flex justify-content-between">
                <span>Policies</span>
                <span
                  >{{ summaryData['ISO']?.policy_status?.published || 0 }}/{{
                    summaryData['ISO']?.policy_status?.total || 0
                  }}</span
                >
              </div>
              <div class="progress-policies progress my-2">
                <div
                  class="progress-bar"
                  [ngClass]="{
                    'bg-success':
                      percentage(
                        summaryData['ISO']?.policy_status?.published || 0,
                        summaryData['ISO']?.policy_status?.total || 0
                      ) >= 50,
                    'bg-warning':
                      percentage(
                        summaryData['ISO']?.policy_status?.published || 0,
                        summaryData['ISO']?.policy_status?.total || 0
                      ) < 50
                  }"
                  [style.width.%]="
                    percentage(
                      summaryData['ISO']?.policy_status?.published || 0,
                      summaryData['ISO']?.policy_status?.total || 0
                    )
                  "
                ></div>
              </div>
              <div class="d-flex justify-content-between">
                <span>Procedures</span>
                <span
                  >{{ summaryData['ISO']?.procedure_status?.published || 0 }}/{{
                    summaryData['ISO']?.procedure_status?.total || 0
                  }}</span
                >
              </div>
              <div class="progress-policies progress my-2">
                <div
                  class="progress-bar"
                  [ngClass]="{
                    'bg-success':
                      percentage(
                        summaryData['ISO']?.procedure_status?.published || 0,
                        summaryData['ISO']?.procedure_status?.total || 0
                      ) >= 50,
                    'bg-warning':
                      percentage(
                        summaryData['ISO']?.procedure_status?.published || 0,
                        summaryData['ISO']?.procedure_status?.total || 0
                      ) < 50
                  }"
                  [style.width.%]="
                    percentage(
                      summaryData['ISO']?.procedure_status?.published || 0,
                      summaryData['ISO']?.procedure_status?.total || 0
                    )
                  "
                ></div>
              </div>
              <div class="d-flex justify-content-between">
                <span>Evidences</span>
                <span
                  >{{ summaryData['ISO']?.evidence_status?.good_standing || 0 }}/{{
                    summaryData['ISO']?.evidence_status?.total || 0
                  }}</span
                >
              </div>
              <div class="progress-policies progress my-2">
                <div
                  class="progress-bar"
                  [ngClass]="{
                    'bg-success':
                      percentage(
                        summaryData['ISO']?.evidence_status?.good_standing || 0,
                        summaryData['ISO']?.evidence_status?.total || 0
                      ) >= 50,
                    'bg-warning':
                      percentage(
                        summaryData['ISO']?.evidence_status?.good_standing || 0,
                        summaryData['ISO']?.evidence_status?.total || 0
                      ) < 50
                  }"
                  [style.width.%]="
                    percentage(
                      summaryData['ISO']?.evidence_status?.good_standing || 0,
                      summaryData['ISO']?.evidence_status?.total || 0
                    )
                  "
                ></div>
              </div>
            </div>
          </div>

          <hr class="m-0 mx-3" *ngIf="!frameworks[2].alreadyAddedProgram" />
          <div class="d-flex justify-content-end py-3 pr-2">
            <button
              *ngIf="!frameworks[2].alreadyAddedProgram"
              class="btn-folder "
              [ngClass]="{ 'cb-btn-secondary': frameworks[2].disabled, 'cb-btn-primary': !frameworks[2].disabled }"
              (click)="frameworks[2].disabled ? handleEvent(frameworks[2]) : gotoSelectFramework(frameworks[2].name)"
            >
              {{ frameworks[2].disabled ? (complianceStatus[frameworks[2].name] ? 'Joined' : 'Join waitlist') : 'Add' }}
            </button>
          </div>
        </div>
      </div>
      <!-- HIPAA Card -->
      <!-- 
      <div class="chart-row">
        <div
          class="d-inline-block control-chart-container position-relative mt-3"
          [class.control-chart-disabled]="frameworks[3].disabled"
          *ngIf="!frameworks[3].hidden"
        >
          <ng-container *ngTemplateOutlet="frameworks[3].disabled ? comingSoon : null"> </ng-container>
          <div class="mt-3 mx-3 control-title"><img src="./../../../assets/images/core/gdpr.svg" /> GDPR</div>
          <div
            class="d-flex px-3 pb-3 justify-content-between"
            [ngClass]="{ 'mt-3': frameworks[3].alreadyAddedProgram || frameworks[3].disabled }"
          >
            <div class="control-chart mt-4 ml-2 position-relative">
              <canvas id="gdpr"></canvas>
              <div class="w-100p text-center position-absolute control-summary">
                <div class="control-chart-subtitle">
                  PROGRESS
                </div>
                <div class="control-chart-total-percentage" style="font-size: 16px;">
                  {{
                    percentage(
                      summaryData['GDPR']?.pgm_unit_status?.COMPLETED,
                      summaryData['GDPR']?.pgm_unit_status?.INCOMPLETE +
                        summaryData['GDPR']?.pgm_unit_status?.PENDING +
                        summaryData['GDPR']?.pgm_unit_status?.COMPLETED
                    )
                  }}%
                </div>
              </div>
            </div>
            <div class="mt-4 ml-1 summary-block">
              <div class="d-flex justify-content-between">
                <span>Policies</span>
                <span
                  >{{ summaryData['GDPR']?.policy_status?.published || 0 }}/{{
                    summaryData['GDPR']?.policy_status?.total || 0
                  }}</span
                >
              </div>
              <div class="progress-policies progress my-2">
                <div
                  class="progress-bar"
                  [ngClass]="{
                    'bg-success':
                      percentage(
                        summaryData['GDPR']?.policy_status?.published || 0,
                        summaryData['GDPR']?.policy_status?.total || 0
                      ) >= 50,
                    'bg-warning':
                      percentage(
                        summaryData['GDPR']?.policy_status?.published || 0,
                        summaryData['GDPR']?.policy_status?.total || 0
                      ) < 50
                  }"
                  [style.width.%]="
                    percentage(
                      summaryData['GDPR']?.policy_status?.published || 0,
                      summaryData['GDPR']?.policy_status?.total || 0
                    )
                  "
                ></div>
              </div>
              <div class="d-flex justify-content-between">
                <span>Procedures</span>
                <span
                  >{{ summaryData['GDPR']?.procedure_status?.published || 0 }}/{{
                    summaryData['GDPR']?.procedure_status?.total || 0
                  }}</span
                >
              </div>
              <div class="progress-policies progress my-2">
                <div
                  class="progress-bar"
                  [ngClass]="{
                    'bg-success':
                      percentage(
                        summaryData['GDPR']?.procedure_status?.published || 0,
                        summaryData['GDPR']?.procedure_status?.total || 0
                      ) >= 50,
                    'bg-warning':
                      percentage(
                        summaryData['GDPR']?.procedure_status?.published || 0,
                        summaryData['GDPR']?.procedure_status?.total || 0
                      ) < 50
                  }"
                  [style.width.%]="
                    percentage(
                      summaryData['GDPR']?.procedure_status?.published || 0,
                      summaryData['GDPR']?.procedure_status?.total || 0
                    )
                  "
                ></div>
              </div>
              <div class="d-flex justify-content-between">
                <span>Evidences</span>
                <span
                  >{{ summaryData['GDPR']?.evidence_status?.good_standing || 0 }}/{{
                    summaryData['GDPR']?.evidence_status?.total || 0
                  }}</span
                >
              </div>
              <div class="progress-policies progress my-2">
                <div
                  class="progress-bar"
                  [ngClass]="{
                    'bg-success':
                      percentage(
                        summaryData['GDPR']?.evidence_status?.good_standing || 0,
                        summaryData['GDPR']?.evidence_status?.total || 0
                      ) >= 50,
                    'bg-warning':
                      percentage(
                        summaryData['GDPR']?.evidence_status?.good_standing || 0,
                        summaryData['GDPR']?.evidence_status?.total || 0
                      ) < 50
                  }"
                  [style.width.%]="
                    percentage(
                      summaryData['GDPR']?.evidence_status?.good_standing || 0,
                      summaryData['GDPR']?.evidence_status?.total || 0
                    )
                  "
                ></div>
              </div>
            </div>
          </div>
          <hr class="m-0 mx-3" *ngIf="!frameworks[3].disabled && !frameworks[3].alreadyAddedProgram" />
          <div class="d-flex justify-content-end py-3 pr-2">
            <button
              *ngIf="!frameworks[3].disabled && !frameworks[3].alreadyAddedProgram"
              class="btn-folder cb-button-primary"
              (click)="gotoSelectFramework(frameworks[3].name)"
            >
              Add
            </button>
          </div>
        </div>

        <div
          class="d-inline-block control-chart-container mt-3 position-relative"
          [class.control-chart-disabled]="frameworks[4].disabled"
          *ngIf="!frameworks[4].hidden"
        >
          <ng-container *ngTemplateOutlet="frameworks[4].disabled ? comingSoon : null"> </ng-container>
          <div class="mt-3 mx-3 control-title"><img src="./../../../assets/images/core/fisma.svg" /> FISMA</div>
          <div
            class="d-flex px-3 pb-3 justify-content-between"
            [ngClass]="{ 'mt-3': frameworks[4].alreadyAddedProgram || frameworks[4].disabled }"
          >
            <div class="control-chart mt-4 ml-2 position-relative">
              <canvas id="fisma"></canvas>
              <div class="w-100p text-center position-absolute control-summary">
                <div class="control-chart-subtitle">
                  PROGRESS
                </div>
                <div class="control-chart-total-percentage" style="font-size: 16px;">
                  <div class="control-chart-total-percentage" style="font-size: 16px;">
                    {{
                      percentage(
                        summaryData['FISMA']?.pgm_unit_status?.COMPLETED,
                        summaryData['FISMA']?.pgm_unit_status?.INCOMPLETE +
                          summaryData['FISMA']?.pgm_unit_status?.PENDING +
                          summaryData['FISMA']?.pgm_unit_status?.COMPLETED
                      )
                    }}%
                  </div>
                </div>
              </div>
            </div>
            <div class="mt-4 ml-1 summary-block">
              <div class="d-flex justify-content-between">
                <span>Policies</span>
                <span
                  >{{ summaryData['FISMA']?.policy_status?.published || 0 }}/{{
                    summaryData['FISMA']?.policy_status?.total || 0
                  }}</span
                >
              </div>
              <div class="progress-policies progress my-2">
                <div
                  class="progress-bar"
                  [ngClass]="{
                    'bg-success':
                      percentage(
                        summaryData['FISMA']?.policy_status?.published || 0,
                        summaryData['FISMA']?.policy_status?.total || 0
                      ) >= 50,
                    'bg-warning':
                      percentage(
                        summaryData['FISMA']?.policy_status?.published || 0,
                        summaryData['FISMA']?.policy_status?.total || 0
                      ) < 50
                  }"
                  [style.width.%]="
                    percentage(
                      summaryData['FISMA']?.policy_status?.published || 0,
                      summaryData['FISMA']?.policy_status?.total || 0
                    )
                  "
                ></div>
              </div>
              <div class="d-flex justify-content-between">
                <span>Procedures</span>
                <span
                  >{{ summaryData['FISMA']?.procedure_status?.published || 0 }}/{{
                    summaryData['FISMA']?.procedure_status?.total || 0
                  }}</span
                >
              </div>
              <div class="progress-policies progress my-2">
                <div
                  class="progress-bar"
                  [ngClass]="{
                    'bg-success':
                      percentage(
                        summaryData['FISMA']?.procedure_status?.published || 0,
                        summaryData['FISMA']?.procedure_status?.total || 0
                      ) >= 50,
                    'bg-warning':
                      percentage(
                        summaryData['FISMA']?.procedure_status?.published || 0,
                        summaryData['FISMA']?.procedure_status?.total || 0
                      ) < 50
                  }"
                  [style.width.%]="
                    percentage(
                      summaryData['FISMA']?.procedure_status?.published || 0,
                      summaryData['FISMA']?.procedure_status?.total || 0
                    )
                  "
                ></div>
              </div>
              <div class="d-flex justify-content-between">
                <span>Evidences</span>
                <span
                  >{{ summaryData['FISMA']?.evidence_status?.good_standing || 0 }}/{{
                    summaryData['FISMA']?.evidence_status?.total || 0
                  }}</span
                >
              </div>
              <div class="progress-policies progress my-2">
                <div
                  class="progress-bar"
                  [ngClass]="{
                    'bg-success':
                      percentage(
                        summaryData['FISMA']?.evidence_status?.good_standing || 0,
                        summaryData['FISMA']?.evidence_status?.total || 0
                      ) >= 50,
                    'bg-warning':
                      percentage(
                        summaryData['FISMA']?.evidence_status?.good_standing || 0,
                        summaryData['FISMA']?.evidence_status?.total || 0
                      ) < 50
                  }"
                  [style.width.%]="
                    percentage(
                      summaryData['FISMA']?.evidence_status?.good_standing || 0,
                      summaryData['FISMA']?.evidence_status?.total || 0
                    )
                  "
                ></div>
              </div>
            </div>
          </div>
          <hr class="m-0 mx-3" *ngIf="!frameworks[4].disabled && !frameworks[4].alreadyAddedProgram" />
          <div class="d-flex justify-content-end py-3 pr-2">
            <button
              *ngIf="!frameworks[4].disabled && !frameworks[4].alreadyAddedProgram"
              class="btn-folder cb-button-primary"
              (click)="gotoSelectFramework(frameworks[4].name)"
            >
              Add
            </button>
          </div>
        </div>

        <div
          class="d-inline-block control-chart-container mt-3 position-relative"
          [class.control-chart-disabled]="frameworks[5].disabled"
          *ngIf="!frameworks[5].hidden"
        >
          <ng-container *ngTemplateOutlet="frameworks[5].disabled ? comingSoon : null"> </ng-container>
          <div class="mt-3 mx-3 control-title"><img src="./../../../assets/images/core/cmmc.svg" /> CMMC</div>
          <div
            class="d-flex px-3 pb-3 justify-content-between"
            [ngClass]="{ 'mt-3': frameworks[5].alreadyAddedProgram || frameworks[5].disabled }"
          >
            <div class="control-chart mt-4 ml-2 position-relative">
              <canvas id="cmmc"></canvas>
              <div class="w-100p text-center position-absolute control-summary">
                <div class="control-chart-subtitle">
                  PROGRESS
                </div>
                <div class="control-chart-total-percentage" style="font-size: 16px;">
                  <div class="control-chart-total-percentage" style="font-size: 16px;">
                    {{
                      percentage(
                        summaryData['CMMC']?.pgm_unit_status?.COMPLETED,
                        summaryData['CMMC']?.pgm_unit_status?.INCOMPLETE + summaryData['CMMC']?.pgm_unit_status?.PENDING
                      )
                    }}%
                  </div>
                </div>
              </div>
            </div>
            <div class="mt-4 ml-1 summary-block">
              <div class="d-flex justify-content-between">
                <span>Policies</span>
                <span
                  >{{ summaryData['CMMC']?.policy_status?.published || 0 }}/{{
                    summaryData['CMMC']?.policy_status?.total || 0
                  }}</span
                >
              </div>
              <div class="progress-policies progress my-2">
                <div
                  class="progress-bar"
                  [ngClass]="{
                    'bg-success':
                      percentage(
                        summaryData['CMMC']?.policy_status?.published || 0,
                        summaryData['CMMC']?.policy_status?.total || 0
                      ) >= 50,
                    'bg-warning':
                      percentage(
                        summaryData['CMMC']?.policy_status?.published || 0,
                        summaryData['CMMC']?.policy_status?.total || 0
                      ) < 50
                  }"
                  [style.width.%]="
                    percentage(
                      summaryData['CMMC']?.policy_status?.published || 0,
                      summaryData['CMMC']?.policy_status?.total || 0
                    )
                  "
                ></div>
              </div>
              <div class="d-flex justify-content-between">
                <span>Procedures</span>
                <span
                  >{{ summaryData['CMMC']?.procedure_status?.published || 0 }}/{{
                    summaryData['CMMC']?.procedure_status?.total || 0
                  }}</span
                >
              </div>
              <div class="progress-policies progress my-2">
                <div
                  class="progress-bar"
                  [ngClass]="{
                    'bg-success':
                      percentage(
                        summaryData['CMMC']?.procedure_status?.published || 0,
                        summaryData['CMMC']?.procedure_status?.total || 0
                      ) >= 50,
                    'bg-warning':
                      percentage(
                        summaryData['CMMC']?.procedure_status?.published || 0,
                        summaryData['CMMC']?.procedure_status?.total || 0
                      ) < 50
                  }"
                  [style.width.%]="
                    percentage(
                      summaryData['CMMC']?.procedure_status?.published || 0,
                      summaryData['CMMC']?.procedure_status?.total || 0
                    )
                  "
                ></div>
              </div>
              <div class="d-flex justify-content-between">
                <span>Evidences</span>
                <span
                  >{{ summaryData['CMMC']?.evidence_status?.good_standing || 0 }}/{{
                    summaryData['CMMC']?.evidence_status?.total || 0
                  }}</span
                >
              </div>
              <div class="progress-policies progress my-2">
                <div
                  class="progress-bar"
                  [ngClass]="{
                    'bg-success':
                      percentage(
                        summaryData['CMMC']?.evidence_status?.good_standing || 0,
                        summaryData['CMMC']?.evidence_status?.total || 0
                      ) >= 50,
                    'bg-warning':
                      percentage(
                        summaryData['CMMC']?.evidence_status?.good_standing || 0,
                        summaryData['CMMC']?.evidence_status?.total || 0
                      ) < 50
                  }"
                  [style.width.%]="
                    percentage(
                      summaryData['CMMC']?.evidence_status?.good_standing || 0,
                      summaryData['CMMC']?.evidence_status?.total || 0
                    )
                  "
                ></div>
              </div>
            </div>
          </div>
          <hr class="m-0 mx-3 " *ngIf="!frameworks[5].disabled && !frameworks[5].alreadyAddedProgram" />
          <div class="d-flex justify-content-end py-3 pr-2">
            <button
              *ngIf="!frameworks[5].disabled && !frameworks[5].alreadyAddedProgram"
              class="btn-folder cb-button-primary"
              (click)="gotoSelectFramework(frameworks[5].name)"
            >
              Add
            </button>
          </div>
        </div>
      </div> -->
      <!-- GDPR Card -->

      <ng-template #comingSoon>
        <div class="mr-2 text-right position-absolute " style="top: 4px;right: 0;">
          <span class="badge badge-warning p-1 mt-1">Coming soon</span>
        </div>
      </ng-template>
    </div>
  </div>
</div>
